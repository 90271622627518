<template>
  <div>
    <Loading v-if="shouldShowSpinner"></Loading>
    <h1 id="edit-article-title" v-if="!fetchError" class="title mb-6">{{ pageHeading }}</h1>
    <MessageBlock v-if="fetchError" heading="Error" class="is-danger">
      <p>That article doesn't exist, or there was a problem fetching data.</p>
    </MessageBlock>
    <div v-else>
      <div class="tabs is-boxed" id="edit-article-tabs-container" :class="{ 'is-disabled': isNewArticleFromScratch }">
        <ul>
          <li v-for="(tab, key) in tabs" :key="key" :class="{ 'is-active': currentTab === key }">
            <a href @click.prevent="setCurrentTab(key, true)">{{ tab.title }}</a>
          </li>
        </ul>
      </div>
      <component
        :is="currentTabComponent"
        :formObserver="currentTab"
        :isFormLocked="isFormLocked"
        :isFormSaving="isFormSaving"
        :isArticleReadOnly="isArticleReadOnly"
        :saveError="saveError"
        :validationErrors="validationErrors"
        @getArticle="getArticle"
        @saveArticle="saveArticle"
        @discardArticle="sendArticleToDiscarded"
        @sendForApproval="sendArticleToReview"
        @switchTab="setCurrentTab"/>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { TabBasic, TabClassification, TabCompounds, TabRelated, TabPreview } from '@/components/marinlit/edit';
import { MessageBlock, Loading } from '@/components/shared';
import ArticleDataMixin from '@/mixins/marinlit/article-data-mixin';
import { WorkflowLabels } from '@/workflow-labels';
import { mapFields } from 'vuex-map-fields';
import { mapGetters } from 'vuex';

export default {
  name: 'MarinLitEdit',
  mixins: [ArticleDataMixin],
  metaInfo () {
    return { title: this.pageHeading }
  },
  beforeRouteLeave(to, from, next) {
    // Reset the editor when finished, otherwise the edited article 
    // details appear briefly when returning to edit another article.
    this.resetArticleEditorAction();
    next();
  },
  data() {
    return {
      currentTab: 'Basic',
      tabs: {
        'Basic': { title: 'Basic info' },
        'Classification': { title: 'Classification' },
        'Compounds': { title: 'Compounds' },
        'Related': { title: 'Related articles' },
        'Preview': { title: 'Preview' },
      },
      isFormLoading: false,
      isFormSaving: false,
      saveError: null,
      fetchError: false,
      validationErrors: {},
    };
  },
  computed: {
    ...mapFields('MarinLitSearch', {
      StatusMarinLit: 'SearchParams.status.value'
    }),
    ...mapGetters('MarinLitSearch', [
      'getSearchPills',
      'getSearchQueryString',
      'getSearchQueryStringWithoutStatus',
    ]),
    ...mapState('MarinLitEditor', {
      article: state => state.article,
    }),
    isFormLocked() {
      return this.isFormLoading || this.isFormSaving || this.isArticleReadOnly;
    },
    isNewArticleFromScratch() {
      return !this.$route.params.id;
    },
    pageHeading() {
      let heading = 'Loading...';
      if (this.isNewArticleFromScratch) heading = 'New article';
      else if (this.fetchError) heading = 'Error';
      else if (this.article.marinLitId) {
        heading = `${!this.isArticleReadOnly ? 'Edit' : ''} ${this.article.marinLitId} `
                + `(${WorkflowLabels[this.article.status]}${this.isArticleReadOnly ? ', read-only)' : ')'}`;
      }
      return heading;
    },
    currentTabComponent: function() {
      return 'Tab' + this.currentTab;
    },
    shouldShowSpinner(){
      // console.log("should show spinner");
      if(this.isNewArticleFromScratch){
        // console.log("is article from scratch");
        return false;
      }

      // console.log("this.article.marinLitId " + this.article.marinLitId);
      return (this.article.marinLitId == undefined) ? true : false;
    },
  },
  methods: {
    ...mapActions('MarinLitEditor', [
      'resetArticleEditorAction',
      'importArticleToEditorAction',
    ]),
    setCurrentTab(tab, noScroll) {
      if (!this.isNewArticleFromScratch && tab) {
        this.currentTab = tab;
        // Don't scroll when clicking directly on a tab
        if (!noScroll) {
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }
      }
    },
    async getArticle(articleId) {
      this.isFormLoading = true;
      const result = await this.dbGetArticle(articleId);
      if (result.Data && result.Data.marinLitId) {
        const article = result.Data;
        this.importArticleToEditorAction(article);
      }
      else {
        this.fetchError = true;
      }
      this.isFormLoading = false;
    },
    async saveArticle(nextTab) {
      this.clearErrors();
      this.isFormSaving = true;
      if (this.isNewArticleFromScratch) {
        await this.saveNewArticle(nextTab);
      } else {
        await this.updateArticle(nextTab);
      }
      this.isFormSaving = false;
    },
    async saveNewArticle(nextTab) {
      const result = await this.dbAddSingleArticle(this.article);
      if (result.Data && result.Data.ok) {
        this.$router.replace({
          name: 'MarinLitEdit',
          params: { id: result.Data.articleId }
        });
        this.setCurrentTab(nextTab);
      }
      else {
        this.handleArticleSubmissionError(result.ServiceStatus.Error);
      }
    },
    async updateArticle(nextTab) {
      const result = await this.dbUpdateArticle(this.article);
      if (result.Data && result.Data.ok) {
        this.setCurrentTab(nextTab);
      }
      else {
        this.handleArticleSubmissionError(result.ServiceStatus.Error);
      }
    },
    handleArticleSubmissionError(error) {
      const validationErrors = error.validationErrors && Object.keys(error.validationErrors).length
        ? error.validationErrors
        : null;
      if (validationErrors) {
        // Pass any field validation errors down to the tab components as props
        this.addValidationErrors(validationErrors);
      }
      else {
        // Something else went wrong, e.g. API unavailable, ID mismatch
        this.saveError = error.statusText ? error.statusText : error.errorMessage;
      }
    },
    async sendArticleToDiscarded() {
      // Return to article list if the article was never saved
      if (this.isNewArticleFromScratch) {        
        await this.$router.push({ name: 'MarinLit' });
        const currentState = this[`Status${this.$route.name}`];   
        this[`Status${this.$route.name}`] = currentState;
      }
      else {
        this.isFormSaving = true;
        const result = await this.dbSendToDiscarded(this.article.articleId);
        if (result.Data && result.Data.ok) {
          const sameStatus = this.StatusMarinLit == result.Data.documentStatus;
          this.startPolling(result.Data.articleId, sameStatus, this.getSearchQueryString);
        }
        else {
          window.alert(result.ServiceStatus.Error);
          this.isFormSaving = false;
        }
      }
    },
    async sendArticleToReview() {
      this.isFormSaving = true;
      const result = await this.dbSendToReview(this.article.articleId);
      if (result.Data && result.Data.ok) {
        const sameStatus = this.StatusMarinLit == result.Data.documentStatus;
        this.startPolling(result.Data.articleId, sameStatus, this.getSearchQueryString);
      }
      else {
        window.alert(result.ServiceStatus.Error);
        this.isFormSaving = false;
      }
    },
    startPolling(articleId, sameStatus, query) {      
      this.statusUpdateCheckInterval = setInterval(async () => {
        // Don't return to article list until it'll be accurate
        const ready = await this.verifyArticleStatusUpdate(articleId, sameStatus, query);
        if (ready) {
          await this.$router.push({ name: 'MarinLit' });
          const currentState = this[`Status${this.$route.name}`];   
          this[`Status${this.$route.name}`] = currentState;     
        }
      }, 1000);
    },
    clearErrors() {
      this.saveError = null;
      this.fetchError = false;
      this.validationErrors = {};
    },
    addValidationErrors(errors) {
      // VeeValidate expects an object in which each key maps to a field's 
      // vid, and each value is an array of strings. Our API returns only 
      // one error (string) per field, so we wrap that value in an array.
      for (const [key, value] of Object.entries(errors)) {
        this.$set(this.validationErrors, key, [value]);
      }
    }
  },
  components: {
    MessageBlock,
    TabBasic,
    TabClassification,
    TabCompounds,
    TabRelated,
    TabPreview,
    Loading
  },
}
</script>
