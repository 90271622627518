import Vue from 'vue';

const axiosConfig = (config) => {
  const token = Vue.prototype.$auth.getAccessToken();
  if (token) config.headers.Authorization = `Bearer ${token}`;
  return config;
}

const axiosError = (error) => {
  return Promise.reject(error);
}

const parseResponse = (response) => {
  if (response.status !== 200) {
    throw Error(response.message);
  }
  const contentType = response.headers['content-type'];
  if (!contentType || !contentType.includes('application/json')) {
    throw new TypeError('Expected application/json');
  }
  if (!response.data) {
    return [];
  }
  let data = response.data;
  if (typeof data !== 'object') {
    data = [];
  }
  return data;
}

const parseSaveError = (error) => {
  if (error.response) {
    if (error.response.data.ok === false) {
      // 400 responses are expected to include an 'ok: false' 
      // property, a custom errorMessage and any validationErrors
      return errorResult(error.response.data);
    }
    else {
      // 404 and 5xx responses can be wrapped in an errorResult
      console.error(error);
      return errorResult(error.response);
    }
  } else if (error.request) {
    // The request was made but no response was received
    console.error(error);
    return errorResult(error);
  } else {
    // Something happened in setting up the request that triggered an error
    console.error(error.message);
    return errorResult(error);
  }
}

const okResult = (data) => {
  return {
    'Data': data,
    'ServiceStatus': {
      'AwaitingResponse': false,
      'HasError': false,
      'Error': null,
    },
  }
}

const errorResult = (error) => {
  return {
    'Data': null,
    'ServiceStatus': {
      'AwaitingResponse': false,
      'HasError': true,
      'Error': error,
    },
  }
}

export {
  axiosConfig,
  axiosError,
  parseResponse,
  parseSaveError,
  okResult,
  errorResult,
}
