import * as axios from 'axios';
import {
  axiosConfig,
  axiosError,
  parseResponse,
  okResult,
  errorResult
} from '@/api';

const ChemspiderApi = axios.create({
  baseURL: process.env.VUE_APP_CHEMSPIDER_BASE_URL,
});

ChemspiderApi.interceptors.request.use(
  (config) => {
    config.headers['Cache-Control'] = 'no-cache';
    config.headers['Pragma'] = 'no-cache';
    config.headers['Expires'] = '0';
    return axiosConfig(config);
  },
  axiosError
);

ChemspiderApi.interceptors.request.use(axiosConfig, axiosError);

const getDatasource = async (datasourceID) => {
  try {
    const response = await ChemspiderApi.get(`/data-sources/${datasourceID}/datasource.json`);
    return okResult(parseResponse(response));
  } catch (error) {
    console.error(error);
    return errorResult(error);
  }
}

const getDatasourceLogo = async (datasourceID) => {
  try {
    const response = await ChemspiderApi.get(`/data-sources/${datasourceID}/${datasourceID}.png`, { responseType: "blob" });
    const base64data = await blobToData(response.data);
    return okResult(base64data);
  } catch (error) {
    console.error(error);
    return errorResult(error);
  }
}

const blobToData = (blob) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  })
}

export const ChemspiderApiService = {
  getDatasource,
  getDatasourceLogo
}