<template>
  <div>
    <Loading v-if="this.monograph.merckIndexId == ''"></Loading>
    <h1 id="edit-monograph-title" v-if="!fetchError" class="title mb-6">
      {{ pageHeading }}
    </h1>

    <MessageBlock v-if="fetchError" heading="Error" class="is-danger">
      <p>That monograph doesn't exist, or there was a problem fetching data.</p>
    </MessageBlock>

    <ValidationObserver v-else tag="div" v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(saveMonograph)">
        <!-- Discard/preview are only available for monographs in draft status -->
        <div v-if="!isMonographReadOnly" class="columns is-mobile mb-5">
          <!-- Discard button and modal -->
          <div class="column">
            <button
              type="button"
              id="btn-discard-monograph"
              class="button is-danger"
              :disabled="isFormBusy"
              @click="isDiscardMonographPromptVisible = true"
            >
              <span class="icon"><i class="fas fa-trash-alt"></i></span>
              <span>Discard monograph</span>
            </button>
            <ModalWindow
              :isOpen="isDiscardMonographPromptVisible"
              :disabled="isFormSaving"
              :heading="`Discard ${
                monograph.title.trim().length
                  ? monograph.title
                  : monograph.merckIndexId.toUpperCase()
              }`"
              @close="dismissDiscardMonographPrompt"
            >
              <template #body>
                <p>
                  Do you really want to discard this monograph and its
                  {{
                    monograph.substances.length > 1
                      ? `${monograph.substances.length} containers`
                      : "container"
                  }}?
                </p>
                <p>This action is permanent and irreversible.</p>
              </template>
              <template #footer>
                <button
                  type="button"
                  class="button is-danger"
                  @click="sendMonographToDiscarded"
                  :class="{ 'is-loading': isFormSaving }"
                >
                  Discard
                </button>
              </template>
            </ModalWindow>
          </div>

          <!-- Preview button and modal -->
          <div class="column">
            <button
              type="button"
              id="btn-preview-monograph"
              class="button is-link is-outlined is-pulled-right"
              :disabled="isFormBusy"
              @click="isMonographPreviewVisible = true"
            >
              <span class="icon"><i class="fas fa-external-link-alt"></i></span>
              <span>Preview monograph</span>
            </button>
            <MonographPreview
              :isOpen="isMonographPreviewVisible"
              :preview="monograph"
              @close="isMonographPreviewVisible = false"
            />
          </div>
        </div>

        <MessageBlock
          v-if="saveError"
          heading="Failed to save monograph"
          class="is-danger"
        >
          <p>{{ saveError }}</p>
        </MessageBlock>

        <!-- Monograph title -->
        <div class="columns is-mobile mb-5">
          <TextInput
            id="title"
            class="column is-half"
            v-model="monograph.title"
            :disabled="isFormBusy || isMonographReadOnly"
            rules="required"
            label="Monograph title"
          />
        </div>

        <!-- Containers -->
        <div v-if="monograph.substances.length" class="table-container mb-5">
          <h2 class="has-text-weight-bold is-size-5 mb-3">Containers</h2>
          <table class="table is-bordered is-fullwidth container-table mb-4">
            <thead>
              <tr>
                <th v-if="showSortingButtons" class="shrink">Order</th>
                <th>Name</th>
                <th>Justification</th>
                <th class="shrink">Actions</th>
              </tr>
            </thead>
            <tbody>
              <ContainerTableRow
                v-for="(container, index) in monograph.substances"
                :key="container.recordId"
                :index="index"
                :container="container"
                :isFormBusy="isFormBusy"
                :isMonographReadOnly="isMonographReadOnly"
                :showSortingButtons="showSortingButtons"
                :numberOfContainers="monograph.substances.length"
                @move="moveContainer"
                @refresh="getMonograph"
              />
            </tbody>
            <tfoot v-if="!isMonographReadOnly">
              <tr>
                <td v-if="showSortingButtons" class="shrink"></td>
                <td>
                  <!-- Add container -->
                  <button
                    type="button"
                    id="btn-add-container"
                    class="button is-small is-link"
                    @click="toggleAddContainerWindow"
                    :disabled="isFormBusy"
                  >
                    <span class="icon"><i class="fas fa-plus"></i></span>
                    <span>Add container</span>
                  </button>
                  <ContainerEditor
                    v-if="isAddContainerWindowOpen"
                    :container="{ articleId: monograph.articleId }"
                    @refresh="getMonograph"
                    @close="toggleAddContainerWindow"
                  />
                </td>
                <td></td>
                <td class="shrink"></td>
              </tr>
            </tfoot>
          </table>
        </div>

        <!-- Comments -->
        <div v-if="monograph.comments.length" class="table-container">
          <h2 class="has-text-weight-bold is-size-5 mb-3">Comments</h2>
          <table class="table is-bordered is-fullwidth mb-4">
            <thead>
              <tr>
                <th>Note</th>
                <th>Added by</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(comment, index) in monograph.comments"
                :key="`comment${index}`"
              >
                <td>{{ comment.note }}</td>
                <td>{{ comment.user }}</td>
                <td>{{ new Date(comment.date).toLocaleString("en-GB") }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <hr />
        <progress
          v-if="isFormSaving"
          class="progress is-link"
          max="100"
        ></progress>
        <div class="columns is-mobile">
          <div class="column">
            <button
              type="button"
              id="btn-go-back"
              @click="$router.push({ name: 'MerckIndex' })"
              class="button"
              :disabled="isFormBusy"
            >
              <span class="icon"><i class="fas fa-backward"></i></span>
              <span>Back to monograph list</span>
            </button>
          </div>
          <div class="column has-text-right" v-if="!isMonographReadOnly">
            <button
              type="submit"
              id="btn-save-changes"
              class="button is-success"
              :class="{ 'is-loading': isFormSaving }"
            >
              <span class="icon"><i class="fas fa-check"></i></span>
              <span>Save changes</span>
            </button>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { MerckIndexDataService } from "@/api";
import { TextInput } from "@/components/forms";
import {
  ContainerEditor,
  ContainerTableRow,
} from "@/components/merckindex/edit";
import { MonographPreview } from "@/components/merckindex/shared";
import { MessageBlock, ModalWindow, Loading } from "@/components/shared";
import { WorkflowLabels } from "@/workflow-labels";
import { WorkflowStatus } from "@/workflow-status";
import { ValidationObserver } from "vee-validate";
import MonographDataMixin from "@/mixins/merckindex/monograph-data-mixin";
import { mapFields } from "vuex-map-fields";
import { mapGetters } from "vuex";

export default {
  name: "MerckIndexEdit",
  mixins: [MonographDataMixin],
  metaInfo() {
    return { title: this.pageHeading };
  },
  async mounted() {
    await this.getMonograph();
  },
  data() {
    return {
      monograph: {
        articleId: "",
        comments: [],
        merckIndexId: "",
        status: WorkflowStatus.DRAFT,
        substances: [],
        title: "",
      },
      isFormLoading: false,
      isFormSaving: false,
      saveError: null,
      fetchError: false,
      isAddContainerWindowOpen: false,
      isDiscardMonographPromptVisible: false,
      isMonographPreviewVisible: false,
    };
  },
  computed: {
    ...mapFields("MerckIndexSearch", {
      StatusMerckIndex: "SearchParams.status.value",
    }),
    ...mapGetters("MerckIndexSearch", [
      "getSearchPills",
      "getSearchQueryString",
      "getSearchQueryStringWithoutStatus",
    ]),
    isFormBusy() {
      return this.isFormLoading || this.isFormSaving;
    },
    pageHeading() {
      let heading = "Loading...";
      if (this.fetchError) heading = "Error";
      else if (this.monograph.merckIndexId) {
        heading =
          `${
            !this.isMonographReadOnly ? "Edit" : ""
          } ${this.monograph.merckIndexId.toUpperCase()} ` +
          `(${WorkflowLabels[this.monograph.status]}${
            this.isMonographReadOnly ? ", read-only)" : ")"
          }`;
      }
      return heading;
    },
    showSortingButtons() {
      return !this.isMonographReadOnly && this.monograph.substances
        ? this.monograph.substances.length > 2
        : false;
    },
  },
  methods: {
    // Monograph operations
    async getMonograph() {
      this.isFormLoading = true;
      const result = await MerckIndexDataService.getMonograph(
        this.$route.params.id
      );
      if (result.Data && result.Data.merckIndexId) {
        this.monograph = result.Data;
      } else {
        this.fetchError = true;
      }
      this.isFormLoading = false;
    },

    dismissDiscardMonographPrompt() {
      // Only allow user to dismiss modal if it's not busy
      this.isDiscardMonographPromptVisible = this.isFormSaving;
    },
    async sendMonographToDiscarded() {
      this.isFormSaving = true;
      const result = await this.dbSendToDiscarded(this.monograph.articleId);
      if (result.Data && result.Data.ok) {
        this.startPolling(result.Data.articleId);
      } else {
        window.alert(result.ServiceStatus.Error);
        this.isFormSaving = false;
      }
    },
    async saveMonograph() {
      // Only the monograph title and order of substances can be modified when 'Save
      // changes' is pressed. All other monograph data is in its containers (substances).
      const monograph = {
        articleId: this.monograph.articleId,
        title: this.monograph.title.trim(),
        status: WorkflowStatus.DRAFT,
        substances: this.monograph.substances.map((s) => s.recordId), // Containers ordered by recordId
      };
      this.isFormSaving = true;
      const result = await MerckIndexDataService.updateMonograph(monograph);
      if (result.Data && result.Data.ok) {
        this.statusUpdateCheckInterval = setInterval(async () => {
          const ready = await this.verifyMonographTitleUpdate(
            monograph.articleId,
            monograph.title,
            this.getSearchQueryString
          );
          if (ready) {
            setTimeout(() => {
              this.verifyMonographStatusUpdate(
                monograph.articleId,
                this.getSearchQueryString
              );
              this.$router.push({ name: "MerckIndex" });
            },1000);
          }
        }, 1000);
      } else {
        this.isFormSaving = false;
        const error = result.ServiceStatus.Error;
        this.saveError = error.statusText
          ? error.statusText
          : error.errorMessage;
      }
    },
    startPolling(articleId) {
      this.statusUpdateCheckInterval = setInterval(async () => {
        // Don't return to monograph list until it'll be accurate
        const ready = await this.verifyMonographStatusUpdate(
          articleId,
          this.getSearchQueryString
        );
        if (ready) {
          await this.$router.push({ name: "MerckIndex" });
          const currentState = this[`Status${this.$route.name}`];
          this[`Status${this.$route.name}`] = currentState;
        }
      }, 1000);
    },

    // Container operations
    moveContainer(fromIndex, toIndex) {
      const containerToMove = this.monograph.substances[fromIndex];
      this.monograph.substances.splice(fromIndex, 1);
      this.monograph.substances.splice(toIndex, 0, containerToMove);
    },
    toggleAddContainerWindow() {
      this.isAddContainerWindowOpen = !this.isAddContainerWindowOpen;
    },
  },
  components: {
    ContainerEditor,
    ContainerTableRow,
    TextInput,
    MessageBlock,
    ModalWindow,
    MonographPreview,
    ValidationObserver,
    Loading,
  },
};
</script>
