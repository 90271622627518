<template>
  <div>
    <div class="compound-list">
      <div v-if="loading || localLoading">
        <Loading />
      </div>
      <div v-if="enhancedCompounds.length === 0">No records found</div>
      <div v-else v-for="(compound, index) in enhancedCompounds" :key="index" :class="['compound-row', { 'compound-row--alternate': index % 2 !== 0 }]">
        <div class="compound-cell">
          <div class="svg-cell">
            <a target="_blank" :href="`${baseLink}/Chemical-Structure.${compound.chemSpiderId}.html`">
              <div v-if="compound.svg" class="structure-img" v-html="compound.svg"></div>
            </a>
          </div>
          <div>
            <strong>{{ compound.compoundName }}</strong>
            <p>ID: {{ compound.chemSpiderId }}</p>
            <p>Status: {{ compound.status }}</p>
            <p>
              Link:
              <a target="_blank" :href="`${baseLink}/Chemical-Structure.${compound.chemSpiderId}.html`">{{
                `${baseLink}/Chemical-Structure.${compound.chemSpiderId}.html`
              }}</a>
            </p>
          </div>
        </div>
      </div>
    </div>

    <div v-if="totalCompounds > enhancedCompounds.length" class="pagination-controls">
      <button @click="prevPage" :disabled="settings.requestedPage === 1">Previous</button>
      <span>Page {{ settings.requestedPage }} of {{ totalPages }}</span>
      <button @click="nextPage" :disabled="settings.requestedPage === totalPages">Next</button>
    </div>
  </div>
</template>

<script>
import Loading from "@/components/shared/loading.vue";
export default {
  name: "CompoundsList",
  components: {
    Loading,
  },
  props: {
    settings: {
      type: Object,
      required: true,
    },
    rawCompounds: {
      type: Array,
      required: true,
    },
    totalCompounds: {
      type: Number,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localLoading: false,
      enhancedCompounds: [],
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.totalCompounds / this.settings.itemsPerPage);
    },
    baseLink() {
      return `${process.env.VUE_APP_CHEMSPIDER_BASE_URL}`;
    },
  },
  watch: {
    rawCompounds: {
      immediate: true,
      handler(newCompounds) {
        this.enhanceCompoundsWithSVG(newCompounds);
      },
    },
  },
  methods: {
    async enhanceCompoundsWithSVG(compounds) {
      try {
        const enhanced = await Promise.all(
          compounds.map(async (c) => {
            const url =
              process.env.NODE_ENV !== "production" ? `/dummy-data/2157.mol` : `${this.baseLink}/compound-data/${c.chemSpiderId}/${c.chemSpiderId}.mol`;

            try {
              const response = await fetch(url);
              const content = await response.text();
              let cutcontent = content.split("M  END")[0] + "M  END";
              let mol = window.RDKit.get_mol(cutcontent);
              if (mol) {
                let mdetails = {
                  width: 100,
                  height: 100,
                  includeRadicals: false,
                };
                return { ...c, svg: mol.get_svg_with_highlights(JSON.stringify(mdetails)) };
              }
            } catch (error) {
              console.error("Error fetching compound data:", error);
              return { ...c };
            }
            return { ...c };
          })
        );
        this.enhancedCompounds = enhanced;
      } catch (error) {
        console.error("Error enhancing compounds:", error);
      }
    },
    prevPage() {
      if (this.settings.requestedPage > 1) {
        this.emitCompoundsRequest(this.settings.requestedPage - 1);
      }
    },
    nextPage() {
      if (this.settings.requestedPage < this.totalPages) {
        this.emitCompoundsRequest(this.settings.requestedPage + 1);
      }
    },
    emitCompoundsRequest(pageNo) {
      this.$emit("get-compounds", pageNo ?? 1);
    },
  },
  mounted() {
    if (!this.totalCompounds > 0) return;
    this.localLoading = true;
    if (!window.RDKit) {
      window
        .initRDKitModule()
        .then(async (RDKit) => {
          window.RDKit = RDKit;
          this.emitCompoundsRequest();
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          this.localLoading = false;
        });
    } else {
      this.emitCompoundsRequest(this.settings.requestedPage);
      this.localLoading = false;
    }
  },
};
</script>

<style scoped>
.compound-list {
  width: 100%;
  border-collapse: collapse;
}

.compound-row {
  background-color: white;
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.compound-row--alternate {
  background-color: #f9f9f9;
}

.compound-cell {
  display: flex;
  flex-direction: row;
}

.compound-cell strong {
  margin-bottom: 5px;
}

.svg-cell {
  margin-right: 20px;
}

.pagination-controls {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pagination-controls button {
  padding: 5px 10px;
}

.pagination-controls span {
  font-weight: bold;
}
</style>
