<template>
  <div class="box p-0 has-background-white-bis">
    <a href @click.prevent="toggleExpanded" role="button" class="title p-5 mb-0 is-5 is-flex level-left">
      <span v-show="!isExpanded" class="icon"><i class="fas fa-plus"></i></span>
      <span v-show="isExpanded" class="icon"><i class="fas fa-minus"></i></span>
      <span class="ml-3">{{ heading }}</span>
    </a>
    <div v-show="isExpanded" class="px-5 pb-3">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TogglePanel',
  data() {
    return {
      isExpanded: this.expanded,
    }
  },
  props: {
    heading: {
      type: String,
      required: true,
    },
    expanded: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    toggleExpanded() {
      this.isExpanded = !this.isExpanded;
      this.$emit('on-expanded', this.isExpanded);
    }
  }
}
</script>
