import { WorkflowStatus } from '@/workflow-status';
import { getField, updateField } from 'vuex-map-fields';
import { getSearchPills, getSearchQueryString, getSearchQueryStringWithoutStatus } from '@/store/search-utils';

export default {

  namespaced: true,

  // STATE
  state: {
    UserList: [],
    CompoundStatusList: [
      'New',
      'New to marine',
      'Revision',
      'New and revised in a subsequent article',
      'New to marine and revised in a subsequent article',
      'Revision and revised in a subsequent article'
    ],
    SearchParams: {
      // METADATA
      'status':                { 'value': WorkflowStatus.DRAFT },
      'createdBy':             { 'label': 'Created by', 'value': '' },
      'lastModifiedBy':        { 'label': 'Last modified by', 'value': '' },
      'datePublishedFrom':     { 'label': 'Published after', 'value': '' },
      'datePublishedTo':       { 'label': 'Published before', 'value': '' },
      'dateCreatedFrom':       { 'label': 'Created after', 'value': '' },
      'dateCreatedTo':         { 'label': 'Created before', 'value': '' },
      'dateLastModifiedFrom':  { 'label': 'Last modified after', 'value': '' },
      'dateLastModifiedTo':    { 'label': 'Last modified before', 'value': '' },

      // ARTICLE
      'articleFreeText':       { 'label': 'Article title / trivial name', 'value': '' },
      'marinLitId':            { 'label': 'MarinLit ID (Article)', 'value': '' },
      'doi':                   { 'label': 'DOI', 'value': '' },

      // COMPOUND
      'compoundName':          { 'label': 'Compound name', 'value': '' },
      'inchiKey'        :      { 'label': 'InChIKey', 'value': '' },
      'compoundMarinLitId':    { 'label': 'MarinLit ID (Compound)', 'value': '' },
      'compoundStatus':        { 'label': 'Compound status', 'value': '' },
    },
  },

  // MUTATIONS
  mutations: {
    updateField,
  },

  // ACTIONS
  actions: {
    
  },

  // GETTERS
  getters: {
    getField,
    getSearchPills,
    getSearchQueryString,
    getSearchQueryStringWithoutStatus,
  },

}
