import { extend } from 'vee-validate';

// For HTML fields (can't initialise before window.tinyMCE exists)
extend('html_required', {
  validate(value, { editorId }) {
    // Make sure editor instance is available to use getContent API
    if (window.tinyMCE && window.tinyMCE.get(editorId)) {
      return {
        required: true,
        valid: !!window.tinyMCE.get(editorId).getContent({ format: 'text' }).trim().length
      };
    }
    // Otherwise fall back to HTML value
    else {
      return {
        required: true,
        valid: !!value.trim().length
      };
    }
  },
  params: ['editorId'],
  message: 'This field is required',
  computesRequired: true,
});

export default {
  data() {
    return {
      //TinyMceApiKey: process.env.VUE_APP_TINYMCE_API_KEY,
      TinyMceConfig: {
        height: 200,
        menubar: false,
        plugins: ['charmap paste'],
        toolbar: 'bold italic | subscript superscript | removeformat | charmap',
        // From TinyMCE 6.0 the forced_root_block option will only accept valid
        // block elements. This is problematic because we want to store fragments 
        // of HTML like H<sub>2</sub>O which aren't wrapped in div or p tags.
        // See https://github.com/tinymce/tinymce/discussions/7342 for more.
        forced_root_block: false,
        deprecation_warnings: false,
        statusbar: false,
        resize: false,
        // When the editor saves, this valid_elements string tells TinyMCE to remove 
        // all elements that are not a "b, i, sub or sup", convert "strong" elements 
        // to "b" elements, and "em" elements to "i" elements.
        valid_elements: 'b/strong,i/em,sub,sup',
        // If TinyMCE is slow to initialise, then the watcher on isFormLocked may not
        // have set the editor mode, so we catch that scenario here.
        nit_instance_callback: (editor) => editor.setMode(this.isFormLocked ? 'readonly' : 'design'),
        content_style: '.mce-content-readonly { color: #7a7a7a; }',
      },
    };
  },
  watch: {
    // Can't use :disabled="isFormLocked" on <HtmlEditor> tags for article title or
    // notes - see https://www.tiny.cloud/docs/integrations/vue/#disabled - because
    // the corresponding watcher in tinymce-vue's Editor.js will often try to call
    // 'setMode' on the editor even if it's not yet initialised, throwing an error.
    // This has been addressed in the official @tinymce/tinymce-vue component for
    // Vue 3, but this project uses Vue 2, making this workaround necessary.
    isFormLocked() {
      if (window.tinyMCE) {
        const editors = window.tinyMCE.get().length;
        for (let i = 0; i < editors; i++) {
          window.tinyMCE.get()[i].setMode(this.isFormLocked ? 'readonly' : 'design');
        }
      }
    }
  },
};
