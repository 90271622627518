import { WorkflowStatus } from '@/workflow-status';

export const WorkflowLabels = Object.freeze({
  [WorkflowStatus.DRAFT]: 'Draft',
  [WorkflowStatus.REVIEW]: 'Awaiting approval',
  [WorkflowStatus.APPROVED]: 'Approved',
  [WorkflowStatus.REJECTED]: 'Rejected',
  [WorkflowStatus.DISCARDED]: 'Discarded',
  [WorkflowStatus.PUBLISHED]: 'Published',
});
