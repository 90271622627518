<template>
  <div id="header-auth" class="auth-state is-size-7">
    <template v-if="authState.isAuthenticated">
      <span v-if="userName">
        Logged in as <b id="header-auth-fullname">{{ userName }}</b> <span class="mx-2">/</span>
      </span>
      <a href id="header-auth-logout" @click.prevent="logout">Log out</a>
    </template>
    <template v-else>
      <a href id="header-auth-login" @click.prevent="login">Log in</a>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'UserInfo',
  computed: {
    ...mapGetters('Auth', [
      'userName',
    ]),
  },
  methods: {
    async login() {
      await this.$auth.signInWithRedirect();
    },
    async logout() {
      await this.$auth.signOut();
    },
  },
}
</script>
