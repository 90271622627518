<template>
  <!-- The content in this component should loosely resemble the -->
  <!-- layout of the subscriber monograph page on the Merck website -->
  <ModalWindow class="monograph-preview" heading="Preview" :isOpen="isOpen" :showCancelButton="false" @close="$emit('close')">
    <template #body>
      <div class="content">
          
        <!-- MONOGRAPH -->
        <div v-if="monograph" class="columns is-mobile">
        
          <div class="column is-two-thirds">
          
            <h2 class="mb-5" v-html="monograph.title"></h2>

            <dl>
              <div><dt>Monograph ID</dt><dd>{{ merckIndexId }}</dd></div>
              <div><dt>Title</dt><dd v-html="monograph.title"></dd></div>
              <div v-if="monograph.casRegistryNumber"><dt>CAS Registry Number</dt><dd>{{ monograph.casRegistryNumber }}</dd></div>
              <div v-if="monograph.casName"><dt>CAS name</dt><dd v-html="monograph.casName"></dd></div>
              <div v-if="monograph.unii"><dt>UNII</dt><dd>{{ monograph.unii }}</dd></div>
              <div v-if="monograph.synonyms.length"><dt>Additional names</dt><dd v-html="monograph.synonyms.join('; ')"></dd></div>
              <div v-if="monograph.manufacturerCodes"><dt>Manufacturer code</dt><dd>{{ monograph.manufacturerCodes }}</dd></div>
              <div v-if="monograph.trademarks.length"><dt>Trademark names</dt><dd>{{ formatTrademarks(monograph.trademarks) }}</dd></div>
              <div v-if="monograph.molecularFormula"><dt>Molecular formula</dt><dd v-html="monograph.molecularFormula"></dd></div>
              <div v-if="monograph.molecularWeight"><dt>Molecular weight</dt><dd>{{ monograph.molecularWeight }}</dd></div>
              <div v-if="monograph.composition"><dt>Percent composition</dt><dd>{{ monograph.composition }}</dd></div>
              <div v-if="monograph.inchi"><dt>Standard InChI</dt><dd>{{ monograph.inchi }}</dd></div>
              <div v-if="monograph.inchiKey"><dt>Standard InChIKey</dt><dd>{{ monograph.inchiKey }}</dd></div>
            </dl>

            <hr />

            <div v-if="monograph.properties" class="block">
              <h3 class="is-size-5">Properties</h3>
              <p v-html="monograph.properties"></p>
            </div>

            <div v-if="monograph.nonMedicalUse" class="block">
              <h3 class="is-size-5">Non-medical use</h3>
              <p v-html="monograph.nonMedicalUse"></p>
            </div>

            <div v-if="monograph.literatureReferences" class="block">
              <h3 class="is-size-5">References</h3>
              <p v-html="monograph.literatureReferences"></p>
            </div>

            <div v-if="monograph.therapeuticCategories.length || monograph.veterinaryTherapeuticCategories.length || monograph.keywords" class="block">
              <h3 class="is-size-5">Classifications</h3>
              <dl>
                <div v-if="monograph.therapeuticCategories.length">
                  <dt>Therapeutic category</dt>                  
                  <dd><span v-for="therapeuticCategory in monograph.therapeuticCategories" v-bind:key="therapeuticCategory">
                    {{`${therapeuticCategory.category} ${therapeuticCategory.subcategory};`}}</span></dd>                  
                </div>
                <div v-if="monograph.veterinaryTherapeuticCategories.length">
                  <dt>Therapeutic category (Vet.)</dt>
                  <dd>{{ monograph.veterinaryTherapeuticCategories.join('; ') }}</dd>
                </div>
                <div v-if="monograph.keywords">
                  <dt>Keywords</dt>
                  <dd>{{ monograph.keywords }}</dd>
                </div>
              </dl>
            </div>

            <div v-if="monograph.note" class="block">
              <h3 class="is-size-5">Notes</h3>
              <p v-html="monograph.note"></p>
            </div>

          </div>

          <div v-if="monograph.pngFilePath || monograph.molFilePath" class="column is-one-third">
            <div class="has-background-white-bis p-3">
              <!-- PNG -->
              <div v-if="monograph.pngFilePath">
                <img :src="filePath(monograph.pngFilePath)" />
                <a :href="filePath(monograph.pngFilePath)" class="button is-info is-fullwidth my-3" download>
                  <span class="icon"><i class="fas fa-download"></i></span>
                  <span class="is-sr-only">Download</span> Image (.png)
                </a>
              </div>
              <!-- MOL -->
              <a v-if="monograph.molFilePath" :href="filePath(monograph.molFilePath)" class="button is-info is-fullwidth" download>
                <span class="icon"><i class="fas fa-download"></i></span>
                <span class="is-sr-only">Download</span> Structure (.mol)
              </a>
            </div>
          </div>

        </div>

        <p v-else>{{ preview.title }} has no containers.</p>

        <!-- DERIVATIVES -->
        <div v-if="derivatives.length">

          <hr />

          <h2 class="is-size-4 mb-5">Derivatives of <span v-html="monograph.title"></span></h2>

          <div v-for="derivative in derivatives" :key="derivative.recordId" class="columns is-mobile derivative">

            <div class="column is-two-thirds">
            
              <h3 v-html="derivative.title" class="is-size-5 mb-5"></h3>

              <dl>
                <div v-if="derivative.casRegistryNumber"><dt>CAS Registry Number</dt><dd>{{ derivative.casRegistryNumber }}</dd></div>
                <div v-if="derivative.casName"><dt>CAS name</dt><dd v-html="derivative.casName"></dd></div>
                <div v-if="derivative.unii"><dt>UNII</dt><dd>{{ derivative.unii }}</dd></div>
                <div v-if="derivative.synonyms.length"><dt>Additional names</dt><dd v-html="derivative.synonyms.join('; ')"></dd></div>
                <div v-if="derivative.manufacturerCodes"><dt>Manufacturer code</dt><dd>{{ derivative.manufacturerCodes }}</dd></div>
                <div v-if="derivative.trademarks.length"><dt>Trademark names</dt><dd>{{ formatTrademarks(derivative.trademarks) }}</dd></div>
                <div v-if="derivative.molecularFormula"><dt>Molecular formula</dt><dd v-html="derivative.molecularFormula"></dd></div>
                <div v-if="derivative.molecularWeight"><dt>Molecular weight</dt><dd>{{ derivative.molecularWeight }}</dd></div>
                <div v-if="derivative.composition"><dt>Percent composition</dt><dd>{{ derivative.composition }}</dd></div>
                <div v-if="derivative.inchi"><dt>Standard InChI</dt><dd>{{ derivative.inchi }}</dd></div>
                <div v-if="derivative.inchiKey"><dt>Standard InChIKey</dt><dd>{{ derivative.inchiKey }}</dd></div>
                <div v-if="derivative.properties"><dt>Properties</dt><dd v-html="derivative.properties"></dd></div>
                <div v-if="derivative.nonMedicalUse"><dt>Non-medical use</dt><dd v-html="derivative.nonMedicalUse"></dd></div>
                <div v-if="derivative.literatureReferences"><dt>References</dt><dd v-html="derivative.literatureReferences"></dd></div>
                <div v-if="derivative.therapeuticCategories.length"><dt>Therapeutic category</dt><dd>{{ derivative.therapeuticCategories.join('; ') }}</dd></div>
                <div v-if="derivative.veterinaryTherapeuticCategories.length"><dt>Therapeutic category (Vet.)</dt><dd>{{ derivative.veterinaryTherapeuticCategories.join('; ') }}</dd></div>
                <div v-if="derivative.keywords"><dt>Keywords</dt><dd>{{ derivative.keywords }}</dd></div>
                <div v-if="derivative.note"><dt>Notes</dt><dd v-html="derivative.note"></dd></div>
              </dl>

            </div>
            
            <div v-if="derivative.pngFilePath || derivative.molFilePath" class="column is-one-third">
              <div class="has-background-white-bis">
                <!-- PNG -->
                <div v-if="derivative.pngFilePath">
                  <img :src="filePath(derivative.pngFilePath)" />
                  <a :href="filePath(derivative.pngFilePath)" class="button is-info is-fullwidth my-3" download>
                    <span class="icon"><i class="fas fa-download"></i></span>
                    <span class="is-sr-only">Download</span> Image (.png)
                  </a>
                </div>
                <!-- MOL -->
                <a v-if="!derivative.molFilePath" :href="filePath(derivative.molFilePath)" class="button is-info is-fullwidth" download>
                  <span class="icon"><i class="fas fa-download"></i></span>
                  <span class="is-sr-only">Download</span> Structure (.mol)
                </a>
              </div>
            </div>

          </div>

        </div>

      </div>
    </template>
    <template #footer>
      <button type="button" class="button" @click="$emit('close')">
        Close
      </button>
    </template>
  </ModalWindow>
</template>

<script>
import { ModalWindow } from '@/components/shared';
export default {
  name: 'MonographPreview',
  props: {
    preview: {
      type: Object,
      required: true,
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    merckIndexId() {
      return this.preview.merckIndexId.toUpperCase();
    },
    monograph() {
      return this.preview.substances[0];
    },
    derivatives() {
      return this.preview.substances.slice(1, this.preview.substances.length);
    },
  },
  methods: {
    formatTrademarks(array) {
      let trademarks = [];
      array.forEach(t => trademarks.push(`${t.name}${t.manufacturer ? ` (${t.manufacturer})` : ''}`));
      return trademarks.join('; ');
    },
    filePath(fileName) {
      // DS-3339 Either /workbench-marinlit/compound-files needs renaming to something that's not
      // specific to MarinLit, or a new /workbench-merck-index/compound-files path is needed. This
      // doesn't make sense right now because Merck Index substance files are found at the former.
      return `${process.env.VUE_APP_MERCKINDEX_FILES}/${fileName}`;
    },
  },
  components: {
    ModalWindow,
  },
};
</script>
