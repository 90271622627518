<template>
  <transition name="fade">
    <div class="box">
      <div class="columns is-mobile is-multiline">
        <div class="column is-three-quarters">
          <a :id="monograph.articleId" :href="`/merckindex/edit/${monograph.articleId}`" @click.prevent="goToMerckIndexEdit" class="is-block mb-2" v-html="monograph.monographTitle"></a>
          <ul v-if="monograph.substanceTitle.length" class="is-size-7">
            <li v-for="container in monograph.substanceTitle" v-html="container" :key="container" />
          </ul>
        </div>
        <div class="column is-one-quarter has-text-right">
          <span :id="monographStatusLabel.toLowerCase()" class="is-block has-text-weight-bold mb-2">{{ monographStatusLabel }}</span>
          <p class="is-size-7">Created {{ formatDate(monograph.createdDate) }}</p>
          <p class="is-size-7">Last modified {{ formatDate(monograph.lastModifiedDate) }}</p>
        </div>
        <WorkflowButtons
          class="column is-full"
          :recordId="monograph.articleId"
          :recordStatus="monograph.status"
          :isReadOnly="isMonographReadOnly"
          @preview="previewMonograph"
          @update="showWorkflowPrompt"
        />
      </div>

      <!-- Monograph preview -->
      <MonographPreview :isOpen="isMonographPreviewVisible" :preview="monographPreview" @close="isMonographPreviewVisible = false" />

      <!-- Workflow prompt modal -->
      <ModalWindow :isOpen="isWorkflowPromptVisible" :disabled="isWorkflowPromptBusy" :heading="workflowPromptHeading" @close="dismissWorkflowPrompt">
        <template #body>
          <MessageBlock v-if="workflowError" class="is-danger" heading="Failed to change status">
            {{ workflowError }}
          </MessageBlock>
          <TextArea class="mb-5" id="workflowComment" label="Comment (optional)" v-model="workflowComment" />
        </template>
        <template #footer>
          <button type="button" class="button is-success" :class="{ 'is-loading': isWorkflowPromptBusy }" @click="updateMonographStatus">Confirm</button>
        </template>
      </ModalWindow>
    </div>
  </transition>
</template>

<script>
import { MerckIndexDataService } from "@/api";
import { TextArea } from "@/components/forms";
import { MonographPreview } from "@/components/merckindex/shared";
import { MessageBlock, ModalWindow, WorkflowButtons } from "@/components/shared";
import { WorkflowLabels } from "@/workflow-labels";
import MonographDataMixin from "@/mixins/merckindex/monograph-data-mixin";
import { mapFields } from "vuex-map-fields";
import { mapGetters } from "vuex";

export default {
  name: "MonographResult",
  mixins: [MonographDataMixin],
  data() {
    return {
      isMonographPreviewVisible: false,
      monographPreview: {},
      workflowButton: "",
      workflowComment: "",
      workflowError: null,
      workflowOperation: "",
      isWorkflowPromptBusy: false,
      isWorkflowPromptVisible: false,
    };
  },
  props: {
    monograph: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapFields("MerckIndexSearch", {
      StatusMerckIndex: "SearchParams.status.value",
    }),
    ...mapGetters("MerckIndexSearch", ["getSearchPills", "getSearchQueryString", "getSearchQueryStringWithoutStatus"]),
    monographStatusLabel() {
      return WorkflowLabels[this.monograph.status];
    },
    workflowPromptHeading() {
      switch (this.workflowButton) {
        case "approval":
          return `Send ${this.monograph.monographTitle} for approval`;
        case "approve":
          return `Approve ${this.monograph.monographTitle}`;
        case "reject":
          return `Reject ${this.monograph.monographTitle}`;
        case "publish":
          return `Publish ${this.monograph.monographTitle}`;
        case "draft":
          return `Return ${this.monograph.monographTitle} to draft`;
        default:
          return "";
      }
    },
  },
  methods: {
    goToMerckIndexEdit() {
      this.$router.push({ name: "MerckIndexEdit", params: { id: this.monograph.articleId } });
    },
    formatDate(date) {
      return new Date(date).toLocaleDateString("en-GB");
    },
    async previewMonograph() {
      const result = await MerckIndexDataService.getMonograph(this.monograph.articleId);
      if (result.Data && result.Data.merckIndexId) {
        this.monographPreview = result.Data;
        this.isMonographPreviewVisible = true;
      } else {
        window.alert(result.ServiceStatus.Error);
      }
    },
    dismissWorkflowPrompt() {
      // Only allow user to dismiss modal if it's not busy
      this.isWorkflowPromptVisible = this.isWorkflowPromptBusy;
    },
    showWorkflowPrompt(operation, key) {
      this.workflowButton = key;
      this.workflowComment = "";
      this.workflowError = null;
      this.workflowOperation = operation;
      this.isWorkflowPromptBusy = false;
      this.isWorkflowPromptVisible = true;
    },
    async updateMonographStatus() {
      this.isWorkflowPromptBusy = true;
      this.workflowComment = this.workflowComment.trim();

      // Make API request from MonographDataMixin, eg. 'dbSendToReview'
      const result = await this[this.workflowOperation](this.monograph.articleId, this.workflowComment);

      // Success, now poll the search API until it reflects the change
      if (result.Data && result.Data.ok) {
        this.statusUpdateCheckInterval = setInterval(async () => {
          const ready = await this.verifyMonographStatusUpdate(result.Data.articleId, this.getSearchQueryString);
          if (ready) {
            this.isWorkflowPromptVisible = false;
            this.$emit("update", this.StatusMerckIndex);
          }
        }, 1000);
      }
      // Failure, so reset the modal window and show the error
      else {
        this.isWorkflowPromptBusy = false;
        // e.g. Status can not move from "draft" to "published"
        this.workflowError = result.ServiceStatus.Error.errorMessage;
      }
    },
  },
  components: {
    MessageBlock,
    ModalWindow,
    MonographPreview,
    TextArea,
    WorkflowButtons,
  },
};
</script>
