<template>
  <div>
    <ValidationProvider tag="div" class="mb-2" :rules="rules" ref="provider" v-slot="{ errors }">
      <label :id="fileType" v-if="label" class="label">
        {{ label }}
        <RequiredAsterisk v-if="rules.includes('required')" />
      </label>
      <div class="compound-data">
        <label :id="getElementId('file', fileType)" :for="id" class="button is-link is-outlined is-flex-grow-1">
          <span class="icon"><i class="fas fa-upload"></i></span>
          <span>{{ fileButtonLabel }}</span>
          <input type="file" @change="onFileChange" :accept="`.${fileType}`" class="is-hidden" :ref="id" :id="id" />
        </label>

        <a :id="getElementId('download', fileType)" :href="fileUrl" download v-bind:style="getDownloadStyles()"
          class="button is-ghost is-flex-grow-1" >
          Download {{ fileTypeUpperCase }}
        </a>

        <span :id="getElementId('delete', fileType)" :title="getBinTitle()" v-bind:class="getBinClasses()"
          style="height: 2.3em; filter: invert(12%) sepia(96%) saturate(4579%) hue-rotate(344deg) brightness(89%) contrast(93%);"
          @click="sendConfirm(fileType)"><i class="fas fa-trash-alt"></i>
        </span>
      </div>

      <!-- Validation message -->
      <p v-if="!!errors.length" class="help is-danger" :id="`${id}Err`">
        {{ errors[0] }}
      </p>
    </ValidationProvider>

    <span v-if="fileName" class="tag pill">
      {{ fileName }}
      <button type="button" class="delete is-small" @click="resetFileInput" :aria-label="`Remove ${fileName}`"></button>
    </span>
  </div>
</template>

<script>
import { MarinLitDataService } from "@/api";
import RequiredAsterisk from "@/components/forms/required-asterisk";
import { ValidationProvider } from "vee-validate";
import { mapFields } from 'vuex-map-fields';

export default {
  name: "FileInput",
  data() {
    return {
      file: null,
      fileRoot: {
        MarinLitEdit: process.env.VUE_APP_MARINLIT_FILES,
        MerckIndexEdit: process.env.VUE_APP_MERCKINDEX_FILES,
      }
    };
  },
  props: {
    id: {
      type: String,
      required: true,
      validator: (value) => {
        return value.length;
      },
    },
    label: {
      type: String,
      default: "",
    },
    fileType: {
      type: String,
      default: "",
      validator: (value) => ["png", "mol", "cdx", "xml"].includes(value),
    },
    filePath: {
      type: String,
      default: "",
    },
    fileSizeLimit: {
      type: Number,
      default: 128 * 1024,
    },
    rules: {
      type: String,
      default: "",
    },
    _value: {
      type: Boolean,
      default: false,
    },
    isActive: {
      type: Boolean,
      default: false
    },
    isReadOnly: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapFields('MerckIndexSearch', {
      StatusMerckIndexEdit: 'SearchParams.status.value'
    }),
    ...mapFields('MarinLitSearch', {
      StatusMarinLitEdit: 'SearchParams.status.value'
    }),
    fileButtonLabel() {
      return `${this.file || this.filePath ? "Replace" : "Upload"} ${this.fileTypeUpperCase
        } file`;
    },

    fileName() {
      return this.file && this.file.name ? this.file.name : "";
    },
    fileTypeUpperCase() {
      return this.fileType.toUpperCase();
    },
    fileUrl() {
      return `${this.fileRoot[this.$route.name]}/${this.filePath}`;
    },
    fileSizeError() {
      return `File must not be larger than ${this.fileSizeLimit / 1024}KB`;
    },
    fileTypeError() {
      return `File type must be ${this.fileTypeUpperCase}`;
    },
  },
  methods: {
    async onFileChange(e) {
      // Validate rules, eg. required
      const { valid } = await this.$refs.provider.validate(e);
      if (valid) {
        const files = e.target.files || e.dataTransfer.files;

        if (files.length) {
          this.file = files[0];

          // Validate file type
          if (!this.file.name.toLowerCase().endsWith(this.fileType)) {
            this.$refs.provider.setErrors([this.fileTypeError]);
            this.resetFileInput();
          }

          // Validate file size
          else if (this.file.size > this.fileSizeLimit) {
            this.$refs.provider.setErrors([this.fileSizeError]);
            this.resetFileInput();
          }

          // For MarinLit ONLY, try to extract molecular formula, exact mass, standard
          // InChI and InChIKey from a given MOL file
          else if (
            this.$route.name === "MarinLitEdit" &&
            this.fileType === "mol"
          ) {
            this.file = null; // Hide file capsule until validated
            await this.extractMolData(files[0]);
          }

          // Success
          else {
            this.$emit("update", this.fileType, this.file);
          }
        }
      }
    },
    async extractMolData(file) {
      let formData = new FormData();
      formData.append("file", file);
      // Only attempt this if you know that the user has permission to work on MarinLit
      // otherwise the API will return 403 Forbidden and the file won't be accepted.
      const result = await MarinLitDataService.getCompoundDataFromMolFile(
        formData
      );
      if (result.Data) {
        this.file = file;
        this.$emit("update", this.fileType, this.file, result.Data);
      } else {
        this.$refs.provider.setErrors([
          "Failed to extract compound data from MOL file",
        ]);
        this.resetFileInput();
      }
    },

    resetFileInput() {
      this.file = null;
      this.$refs[this.id].value = null;
      this.$refs.provider.syncValue(this.file);
      this.$emit("update", this.fileType, null);
    },
    sendConfirm(type) {
      this.$root.$emit("confirm", type);
    },
    getElementId(action, type) {
      return `${action}-${type}`;
    },
    getBinTitle() {
      return (this.filePath && !this.isActive && this.fileType != 'xml') ? `delete ${this.fileType} file` : '';
    },
    getFileName() {
      return this.file && this.file.name ? this.file.name : "";
    },
    getBinClasses() {
      return (this[`Status${this.$route.name}`] == 'draft' && this.filePath && !this.isActive && this.fileType != 'xml' && !this.fileName && !this.isReadOnly) ? 'icon is-flex-grow-1 is-clickable' : 'is-invisible is-flex-grow-1';
    },
    getDownloadHref() {
      return (this.filePath) ? this.fileUrl : null;
    },
    getDownloadStyles() {
      return (this.isActive || this.filePath) ? 'cursor:pointer; opacity:1; border:0; visibility: visible' : 'visibility:hidden;';
    },
  },
  components: {
    RequiredAsterisk,
    ValidationProvider,
  },
};
</script>
