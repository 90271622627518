<template>
  <tr>
    <!-- Column 1: Sorting -->
    <td v-if="showSortingButtons" class="shrink">
      <button
        v-if="isDerivative"
        type="button"
        aria-label="Move container up"
        class="button is-small is-link is-outlined mr-2"
        @click="$emit('move', index, index - 1)"
        :disabled="isFormBusy || index === 1"
      >
        <span class="icon"><i class="fas fa-arrow-up"></i></span>
      </button>
      <button
        v-if="isDerivative"
        type="button"
        aria-label="Move container down"
        class="button is-small is-link is-outlined"
        @click="$emit('move', index, index + 1)"
        :disabled="isFormBusy || index === numberOfContainers - 1"
      >
        <span class="icon"><i class="fas fa-arrow-down"></i></span>
      </button>
    </td>

    <!-- Column 2: Title -->
    <td>
      <a v-if="!isFormBusy" :id="getId(container.title)" href @click.prevent="viewContainer" v-html="container.title"></a>
      <span v-else :id="container.title" v-html="getId(container.title)"></span>
    </td>

    <!-- Column 3: Justification -->
    <td :id="`${getId(container.title)}-justification`">{{ container.justification }}</td>

    <!-- Column 4: Edit / Delete -->
    <td class="shrink">

      <!-- Edit/Details button and modal window -->
      <button :id="isMonographReadOnly ? 'details' : 'edit'" type="button" class="button is-small is-link" @click="viewContainer" :disabled="isFormBusy">
        <span class="icon"><i class="fas" :class="isMonographReadOnly ? 'fa-file' : 'fa-pen'"></i></span>
        <span>{{ isMonographReadOnly ? 'Details' : 'Edit' }}</span>
      </button>
      <ContainerEditor
        v-if="isContainerEditorOpen"
        :container="containerToView"
        :isMonographReadOnly="isMonographReadOnly"
        @refresh="$emit('refresh')"
        @close="isContainerEditorOpen = false"
      />
      
      <!-- Delete button and modal window -->
      <button v-if="isDerivative && !isMonographReadOnly" type="button" class="button is-small is-danger ml-2" @click="isDeleteContainerPromptVisible = true" :disabled="isFormBusy">
        <span class="icon"><i class="fas fa-trash-alt"></i></span>
        <span>Delete</span>
      </button>
      <ModalWindow
        v-if="isDerivative"
        heading="Delete container"
        :disabled="isContainerDeleting"
        :isOpen="isDeleteContainerPromptVisible"
        @close="hideDeleteContainerPrompt"
      >
        <template #body>
          <MessageBlock v-if="containerDeleteError" class="is-danger" heading="Failed to delete container">
            {{ containerDeleteError }}
          </MessageBlock>
          <p>Do you really want to delete <span v-html="container.title" />?</p>
          <p>This action is permanent and irreversible.</p>
        </template>
        <template #footer>
          <button type="button" class="button is-danger" @click="deleteContainer" :class="{ 'is-loading': isContainerDeleting }">
            Delete
          </button>
        </template>
      </ModalWindow>
      
    </td>
  </tr>
</template>

<script>
import { MerckIndexDataService } from '@/api';
import { ContainerEditor } from '@/components/merckindex/edit';
import { MessageBlock, ModalWindow } from '@/components/shared';

export default {
  name: 'ContainerTableRow',
  data() {
    return {
      containerToView: {},
      containerDeleteError: null,
      isContainerDeleting: false,
      isContainerEditorOpen: false,
      isDeleteContainerPromptVisible: false,
    }
  },
  props: {
    container: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      default: 0,
    },
    numberOfContainers: {
      type: Number,
      default: 1,
    },
    showSortingButtons: {
      type: Boolean,
      default: false,
    },
    isFormBusy: {
      type: Boolean,
      default: false,
    },
    isMonographReadOnly: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isDerivative() {
      // Primary containers can't be moved or deleted
      return this.index > 0;
    },
  },
  methods: {
    async viewContainer() {
      const result = await MerckIndexDataService.getContainer(this.container.recordId);
      if (result.Data) {
        this.containerToView = result.Data;
        this.isContainerEditorOpen = true;
      }
      else {
        window.alert(result.ServiceStatus.Error);
      }
    },
    async deleteContainer() {
      this.isContainerDeleting = true;
      const result = await MerckIndexDataService.deleteContainer(this.container.recordId);
      if (result.Data && result.Data.ok) {
        // Reload the updated monograph so that the container's table row is removed
        this.$emit('refresh');
        this.hideDeleteContainerPrompt();
      }
      else {
        this.isContainerDeleting = false;
        const error = result.ServiceStatus.Error;
        this.containerDeleteError = error.statusText ? error.statusText : error.errorMessage;
      }
    },
    hideDeleteContainerPrompt() {
      this.containerDeleteError = null;
      this.isContainerDeleting = false;
      this.isDeleteContainerPromptVisible = false;
    },
    getId(str){
      return str.replace(/\s+/g, '').toLowerCase();
    }
  },
  components: {
    ContainerEditor,
    MessageBlock,
    ModalWindow,
  },
};
</script>
