<template>
  <transition name="fade">
    <p v-if="error" class="has-text-danger">
      Couldn't fetch related article {{ articleId }} ({{ error }})
    </p>
    <div v-else-if="article" class="box">
      <div class="columns is-mobile">
        <div class="column">
          <p v-html="article.title"></p>
          <p v-if="article.authors"><ArticleAuthors :authors="article.authors" /></p>
          <p v-if="article.journalTitle"><ArticleCitation :article="article" /></p>
          <p v-if="article.doi"><ArticleDoiLink :doi="article.doi" /></p>
          <p v-if="article.marinLitId"><b>MarinLit ID:</b> {{ article.marinLitId }}</p>
        </div>
        <div v-if="showButtons" class="column is-narrow">
          <span class="is-block mb-2">
            <button type="button" class="button is-small is-fullwidth" @click="$emit('remove')" >
              <span class="icon"><i class="fas fa-trash-alt"></i></span>
              <span>Remove</span>
            </button>
          </span>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { ArticleAuthors, ArticleCitation, ArticleDoiLink } from '@/components/shared';
import ArticleDataMixin from '@/mixins/marinlit/article-data-mixin';
export default {
  name: 'ArticleCard',
  mixins: [ArticleDataMixin],
  // Article cards on the 'Related articles' and 'Preview' tabs
  // are responsible for fetching their own static data for display
  async mounted() {
    const result = await this.dbGetArticle(this.articleId);
    if (result.Data && result.Data.marinLitId) {
      this.article = result.Data;
    }
    else {
      this.error = result.ServiceStatus.Error;
    }
  },
  data() {
    return {
      article: null,
      error: null,
    }
  },
  props: {
    articleId: {
      type: String,
      required: true,
    },
    showButtons: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ArticleAuthors,
    ArticleCitation,
    ArticleDoiLink,
  },
}
</script>
