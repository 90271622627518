<template>
  <header class="mb-5">
    <div class="header-content is-flex is-justify-content-space-between is-align-items-center">
      <div class="columns is-flex is-vcentered is-marginless" style="gap:10px">
        <div class="header-title is-narrow">
          <font-awesome-icon :icon="['fas', 'bars']" @click="toggleOptions" class="is-size-4 has-text-black"
            style="cursor:pointer; margin-top:3px"></font-awesome-icon>
        </div>
        <h1 class="title has-text-black has-text-weight-bold">{{ headerTitle }}</h1>
      </div>

      <div class="header-buttons is-flex" style="gap:16px">
        <a v-if="$route.path.includes('/cs-depositions/edit')" href="/cs-depositions" class="has-text-white">
          <button class="button is-flex has-text-black has-background-white" style="gap:10px">View all depositions
          </button>
        </a>
        <a v-if="$route.path.includes('/cs-depositions/datasource/edit') || $route.path.includes('/cs-depositions/datasource/')"
          href="/cs-depositions/datasources" class="has-text-white">
          <button class="button is-flex has-text-black has-background-white" style="gap:10px">View all data sources
          </button>
        </a>
        <a v-if="($route.path.includes('/cs-depositions/datasources') || ($route.path.includes('/cs-depositions/datasource/') && $route.path !== '/cs-depositions/datasource/add'))" 
          href="/cs-depositions/datasource/add"
          class="has-text-white">
          <button class="button is-flex has-text-white has-background-black" style="gap:10px">Add data source
            <font-awesome-icon :icon="['fas', 'plus']" class="is-size-4" />
          </button>
        </a>
        <a v-if="($route.path == '/cs-depositions' || ($route.path.includes('/cs-depositions/edit') && $route.path !== '/cs-depositions/datasource/add'))" 
          href="/cs-depositions/add" class="has-text-white">
          <button class="button is-flex has-text-white has-background-black" style="gap:10px">Add deposition
            <font-awesome-icon :icon="['fas', 'plus']" class="is-size-4" />
          </button>
        </a>
      </div>
    </div>
    <h2 v-if="datasourceName" class="is-size-4 has-text-black" style="margin-left: 32px"><span
        class="has-text-weight-bold">Data source: </span>{{ datasourceName }}</h2>
    <h2 v-if="records" class="is-size-4 has-text-black" style="margin-left: 32px"><span
        class="has-text-weight-bold">Number of records: </span>{{ records }}</h2>
    <div v-click-outside="closeOptions">
      <ul class="options-list" style="width:200px; border:solid 2px black; position: absolute; z-index:1"
        v-if="showOptions">
        <li v-for="(option, index) in options" :key="index" :class="{ 'selected-option': option.href == $route.path }"
          class="px-3 py-2 border-bottom">
          <a :href="option.href" class="has-text-black">{{ option.text }}</a>
        </li>
      </ul>
    </div>
  </header>
</template>
  
<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default {
  props: {
    datasourceName: {
      type: String,
      default: ''
    },
    records: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      showOptions: false,
      options: [
        { text: 'Depositions', href: '/cs-depositions' },
        { text: 'Add deposition', href: '/cs-depositions/add' },
        { text: 'Data sources', href: '/cs-depositions/datasources' },
        { text: 'Add data source', href: '/cs-depositions/datasource/add' }
      ]
    };
  },
  components: {
    FontAwesomeIcon
  },
  methods: {
    toggleOptions() {
      this.showOptions = !this.showOptions;
    },
    closeOptions(event) {
      const svgElement = document.querySelector('.header-title svg');
      // Checks if the click came from the svg element. In that case won't do anything.
      if (svgElement && svgElement.contains(event.target)) {
        return;
      }
      // Closes the options list if the click was done outside of the svg element.
      this.showOptions = false;
    }
  },
  computed: {
    headerTitle() {
      if (this.title) return this.title;
      const path = this.$route.path;
      // const elementID = this.$route.path.split('/').pop();

      // const editDepositions = ('/cs-depositions/edit/');
      // const editDatasources = ('/cs-depositions/datasource/edit/')
      if (path == '/cs-depositions') {
        return 'Depositions';
      }
      if (path == '/cs-depositions/datasources') {
        return 'Data sources';
      }

      if (path == '/cs-depositions/datasource/add') {
        return 'New data source';
      }

      // Temporal, it will end up being a modal
      if (path == '/cs-depositions/add') {
        return 'New Deposition';
      }

      // if (path.includes(editDepositions)) {
      //   return `Deposition: ${elementID}`;
      // }

      // if (path.includes(editDatasources)) {
      //   return `Data source: ${elementID}`;
      // }
      return '';
    }
  },
};

</script>
  
  
<style lang="scss" scoped>
li {
  border-bottom: solid 1px black;
  background-color: white
}

li:last-child {
  border-bottom: none
}

.selected-option {
  background-color: #F2F2F2;
  font-weight: 700
}
</style>