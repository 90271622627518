<template>
  <div>
    <div style="margin-bottom: 16px;">
      <div class="search-bar is-flex is-justify-content-space-between is-align-items-center">
        <div class="search-bar-title" v-if="this.$route.path != '/cs-depositions/datasources'">
          <p v-if="this.$route.path == '/cs-depositions' || this.$route.path == '/cs-depositions/'"
            class="is-size-4 has-text-weight-bold has-text-black">All depositions</p>
          <p v-else class="is-size-4 has-text-weight-bold has-text-black">{{ title }}</p>
        </div>
        <div class="search-bar-filters is-inline-flex is-align-items-center">
          <input type="text" v-if="title != 'History'" v-model="searchQuery" @keyup.enter="search" :placeholder="this.$route.path == '/cs-depositions/datasources' ?
            'Search for data source or number of records' :
            title == 'Files' ?
              'Search for file name or number' :
              'Search ID or file name'" style="width:474px; height:42px;">
          <button v-if="title != 'History'" class="button has-text-white has-background-black has-text-weight-bold ml-3"
            @click="search">Search</button>
          <div v-if="$route.path != '/cs-depositions/datasources' && $route.path != '/cs-depositions/datasources/'"
            class="filter-search is-flex is-align-items-center" style="cursor: pointer; margin-left: 32px"
            @click="showFilters = !showFilters" v-click-outside="closeFilters">
            <p class="custom-font-size-18">Filters </p>
            <font-awesome-icon v-if="!showFilters" :icon="['fa', 'chevron-down']" class="is-size-6 ml-3" />
            <font-awesome-icon v-if="showFilters" :icon="['fa', 'chevron-up']" class="is-size-6 ml-3" />
          </div>
        </div>
      </div>
      <fieldset v-if="showFilters" class="filters-box is-flex is-flex-direction-column custom-font-size-18"
        style="position:absolute; right: 0; width:402px; border:1px solid #A7A7A7; background-color: #F9F9F9; padding:35px; z-index: 2">
        <h4 class="custom-font-size-26 mb-5 has-text-black has-text-weight-bold">Filters</h4>
        <div class="radio-buttons mb-5 ml-1">

          <div class="mb-5" v-if="title != 'History' && title != 'Files'">
            <div class="radio-button">
              <input type="radio" id="datasources" v-model="radioDatasources" value="datasources"
                @click="toggleSelection('datasources')">
              <label for="datasources" class="ml-3">Data source</label>
            </div>
            <select id="datasources" name="datasources" class="mt-3" v-if="radioDatasources" v-model="datasourcesField">
              <option value="Georganics Ltd.">Georganics Ltd.</option>
              <option value="Cayman Chemical">Cayman Chemical</option>
              <option value="Acme">Acme</option>
            </select>
          </div>

          <div class="mb-5" v-if="title == 'Files'">
            <div class="radio-button">
              <input type="radio" id="type" v-model="radioTypes" value="type" @click="toggleSelection('type')">
              <label for="type" class="ml-3">Type</label>
            </div>
            <select id="type" name="type" class="mt-3" v-if="radioTypes" v-model="typesField">
              <option value="">All</option>
              <option value="Original">Original</option>
              <option value="Update">Update</option>
            </select>
          </div>

          <div class="mb-5" v-if="title == 'Files'">
            <div class="radio-button">
              <input type="radio" id="errorFile" v-model="radioErrorFile" value="errorFile"
                @click="toggleSelection('errorFile')">
              <label for="errorFile" class="ml-3">Errors</label>
            </div>
          </div>
          <div class="mb-5" v-if="title == 'Files'">
            <div class="radio-button">
              <input type="radio" id="records" v-model="radioRecords" value="records" @click="toggleSelection('records')">
              <label for="records" class="ml-3">Number of records</label>
            </div>
            <input type="text" class="mt-3" v-if="radioRecords" v-model="recordsField">
          </div>

          <div class="mb-5" v-if="title != 'Files'">
            <div class="radio-button">
              <input type="radio" id="date" v-model="radioDate" value="date" @click="toggleSelection('date')">
              <label for="date" class="ml-3">Date</label>
            </div>
            <input type="date" class="mt-3" v-if="radioDate" v-model="dateField">
          </div>

          <div class="mb-5" v-if="title != 'Files'">
            <div class="radio-button">
              <input type="radio" id="status" v-model="radioStatus" value="status" @click="toggleSelection('status')">
              <label for="status" class="ml-3">Status</label>
            </div>
            <select id="datasources" name="datasources" class="mt-3" v-if="radioStatus" v-model="statusField">
              <option value="Initially processing">Initially processing</option>
              <option value="Awaiting mapping">Awaiting mapping</option>
              <option value="Awaiting approval">Awaiting approval</option>
              <option value="Publishing">Publishing</option>
              <option value="Published">Published</option>
            </select>
          </div>

          <div class="mb-5" v-if="title != 'History' && title != 'Files'">
            <div class="radio-button">
              <input type="radio" id="depositionId" v-model="radioID" value="depositionId"
                @click="toggleSelection('depositionId')">
              <label for="depositionId" class="ml-3">ID</label>
            </div>
            <input type="text" class="mt-3" v-if="radioID" v-model="nameField">
          </div>

          <div class="mb-5" v-if="title != 'Files'">
            <div class="radio-button">
              <input type="radio" id="errorDep" v-model="radioErrorDep" value="errorDep"
                @click="toggleSelection('errorDep')">
              <label for="errorDep" class="ml-3">Depositions including errors</label>
            </div>
          </div>

          <div class="mb-5" v-if="title == 'History'">
            <div class="radio-button">
              <input type="radio" id="submitter" v-model="radioSubmitter" value="submitter"
                @click="toggleSelection('submitter')">
              <label for="submitter" class="ml-3">Submitter</label>
            </div>
            <input type="text" class="mt-3" v-if="radioSubmitter" v-model="submitterField">
          </div>
        </div>
        <div class="buttons is-flex">
          <button class="button has-text-black" style="border:1px solid black" @click="clearFilters">Clear</button>
          <button class="button has-background-black has-text-white has-text-weight-bold" @click="search">Filter</button>
        </div>
      </fieldset>
    </div>
    <table style="width: 100%; word-break: break-all;">
      <thead>
        <tr>
          <th v-for="(header, index) in headers" :key="index"
            class="has-text-centered has-text-weight-bold custom-font-size-18"
            style="padding: 14px 22px; white-space: nowrap">
            <span>{{ header.title }} </span>
            <template v-for="icon in icons">
              <font-awesome-icon :key="icon.title" :icon="['fas', 'arrow-down-wide-short']"
                v-if="header.title == icon.title" class="ml-1" @click="sortListHandler(icon)" :class="{
                  'rotate-180': icon.condition == false,
                  'low-opacity': icon.condition == null
                }" />
            </template>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(content, index) in paginatedContents" :key="index" @click="goToLink($event, content)">
          <td
            v-for="([key, value], index) in Object.entries(content).filter(([key]) => !['showFailed', 'failures', 'datasourceId'].includes(key) && (title !== 'History' || key !== 'compoundCount'))"
            :key="index" class="has-text-centered is-size-6" style="padding: 35px 15px">

            <p v-if="key === 'status'" class="is-flex is-justify-content-center">
              <img v-if="value.parent && value.parent === 'Processing'" src="@/styles/icons/waiting.svg"
                alt="waiting-icon" class="mr-1" />
              <img v-if="value.parent && value.parent === 'Published' && content.failures > 0"
                src="@/styles/icons/info-warning.svg" alt="warning-icon" class="published-icon mr-1"
                style="cursor:pointer" @click="toggleFailures(content)" />
              <span>{{ value.parent && value.parent !== value.label ? `${value.parent}: ${value.label}` : value.label
              }}</span>
            </p>
            <p v-else-if="key == 'historyDate' || key == 'dateCreated' || key == 'dateUpdated'">{{ setDate2(value) }}</p>
            <p v-else-if="key == 'depositionDate'">{{ setDate2(value) }}</p>
            <p v-else>
              {{ value }}
            </p>
            <!-- Failures tooltip for Published Depositions -->
            <div v-if="key === 'status' && value === 'Published' && content.showFailed" style="position:absolute"
              v-click-outside="closeFailuresMessage">
              <div class="arrow"></div>
              <div class="span1">{{ ((content.failures / content.compoundCount) * 100).toFixed(2) }}% failed</div>
              <div class="span2">{{ ((content.failures / content.compoundCount) * 100).toFixed(2) }}% failed</div>
            </div>

          </td>
        </tr>
      </tbody>
    </table>
    <div class="has-text-black mt-4 mb-4 custom-font-size-18">
      <template v-if="filteredContents.length === 0">
        <p class="has-text-centered ">No results found.</p>
      </template>
      <template v-else>
        <p class="has-text-right has-text-black mt-4 mb-4 custom-font-size-18">
          <!-- {{ (currentPage - 1) * itemsPerPage + 1 }}-
          {{ Math.min(currentPage * itemsPerPage, filteredContents.length) }}
          of {{ filteredContents.length }} results -->

          {{ paginatedContents.length }}
          of {{ filteredContents.length }} results

        </p>
      </template>
    </div>

    <div class="pagination is-flex is-flex-wrap-wrap is-justify-content-center" style="margin-top:20px"
      v-if="totalPages > 1">
      <button v-for="page in totalPages" :key="page" @click="changePage(page)" :class="{ active: currentPage === page }"
        class="custom-font-size-18">
        {{ page }}
      </button>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default {
  components: {
    FontAwesomeIcon
  },
  props: {
    title: {
      type: String
    },
    headers: {
      type: Array,
      required: true,
    },
    contents: {
      type: Array,
      required: true,
    },
    activeStatus: {
      type: String,
      default: ''
    },
    sortList: {
      type: Function,
    },
    icons: {
      type: Array
    }
  },
  data() {
    return {
      showFilters: false,
      currentPage: 1,
      itemsPerPage: 20,
      searchQuery: '',
      paginatedContents: [],
      totalPages: 1,
      filteredContents: [],

      radioDatasources: null,
      radioDate: null,
      radioStatus: null,
      radioID: null,
      radioRecords: null,
      radioErrorDep: null,
      radioSubmitter: null,
      radioTypes: null,
      radioErrorFile: null,

      datasourcesField: '',
      dateField: '',
      statusField: '',
      nameField: '',
      recordsField: '',
      errorDepField: false,
      submitterField: '',
      typesField: '',
      errorFileField: false,

      statusesToFilter: ['Processing', 'Awaiting mapping', 'Awaiting approval', 'Publishing', 'Published'],
    };
  },
  methods: {
    toggleFailures(content) {
      content.showFailed = !content.showFailed;
    },
    closeFailuresMessage() {
      const svgElement = document.querySelector('.published-icon svg');

      // Checks if the click came from the svg or the filters box. In that case won't do anything.
      if (svgElement && svgElement.contains(event.target)) {
        return;
      }

      for (let content of this.filteredContents) content.showFailed = false;
    },
    goToLink(event, content) {
      const target = event.target;
      if (target.tagName !== "IMG") {
        if (!this.$route.path.includes('/cs-depositions/edit') &&
          !this.$route.path.includes('/cs-depositions/datasources')) {
          this.$router.push(`/cs-depositions/edit/${content.depositionId}`);
        } else if (this.$route.path.includes('/cs-depositions/datasources')) {
          this.$router.push(`/cs-depositions/datasource/${content.datasourceId}`);
        }
      }
    },
    updatePaginatedContents() {
      this.filteredContents = this.contents.map(content => ({
        ...content,
        showFailed: false
      }));

      // Filters by status
      if (this.statusesToFilter.includes(this.activeStatus)) this.filteredContents = this.filteredContents.filter(content => content.status.parent === this.activeStatus);

      // Filters by general search if done 
      if (this.searchQuery.trim() !== '') {
        const searchLower = this.searchQuery.toLowerCase();
        const searchNumber = parseFloat(this.searchQuery);

        this.filteredContents = this.filteredContents.filter(content =>
          (content.depositionId && content.depositionId.toLowerCase().includes(searchLower)) ||
          (content.dataPath && content.dataPath.substring(content.dataPath.lastIndexOf('/') + 1).toLowerCase().includes(searchLower)) ||
          (content.name && content.name.toLowerCase().includes(searchLower)) ||
          (!isNaN(searchNumber) && content.records.toString().includes(searchNumber.toString())) ||
          (content.fileName && content.fileName.toLowerCase().includes(searchLower))
        );
      }

      // Filters by Filter dropdown 
      if (this.datasourcesField) this.filteredContents = this.filteredContents.filter(content => content.datasourceName.includes(this.datasourcesField));
      if (this.typesField) this.filteredContents = this.filteredContents.filter(content => content.type.includes(this.typesField));

      if (this.dateField && this.title == 'History') {
        this.filteredContents = this.filteredContents.filter(content => content.historyDate.includes(this.dateField));
      } else if (this.dateField) this.filteredContents = this.filteredContents.filter(content => content.depositionDate.includes(this.dateField));


      if (this.statusField) this.filteredContents = this.filteredContents.filter(content => content.status.includes(this.statusField));
      if (this.nameField) this.filteredContents = this.filteredContents.filter(content => content.depositionId.includes(this.nameField));
      if (this.recordsField) this.filteredContents = this.filteredContents.filter(content => content.records.toString() == this.recordsField.toString());

      if (this.errorDepField) this.filteredContents = this.filteredContents.filter(content => content.failures > 0);
      if (this.submitterField) this.filteredContents = this.filteredContents.filter(content => content.submitter.toLowerCase().includes(this.submitterField.toLowerCase()));
      if (this.errorFileField) this.filteredContents = this.filteredContents.filter(content => content.errors > 0);

      // Stablish number of pages
      this.totalPages = Math.ceil(this.filteredContents.length / this.itemsPerPage);

      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      this.paginatedContents = this.filteredContents.slice(start, end);
    },
    search() {
      this.currentPage = 1;
      this.updatePaginatedContents();
      this.showFilters = false;
    },
    resetPage() {
      this.currentPage = 1;
      this.searchQuery = ''
      this.clearFilters();
      this.updatePaginatedContents();
    },
    changePage(page) {
      this.currentPage = page;
      this.updatePaginatedContents();
      window.scrollTo(0, 0);
    },
    closeFilters(event) {
      const svgElement = document.querySelector('.filter-search svg');
      const filtersBoxElement = document.querySelector('.filters-box');
      // Checks if the click came from the svg or the filters box. In that case won't do anything.
      if (svgElement && svgElement.contains(event.target)) {
        return;
      }
      if (filtersBoxElement && filtersBoxElement.contains(event.target)) {
        return;
      }
      // Closes the options list if the click was done outside of the svg element or filters box.
      this.showFilters = false;
    },
    toggleSelection(option) {
      switch (option) {
        case 'datasources':
          if (this.radioDatasources === null) {
            this.radioDatasources = 'datasources';
          } else {
            this.radioDatasources = null;
          }
          this.datasourcesField = ''
          break;
        case 'date':
          if (this.radioDate === null) {
            this.radioDate = 'date';
          } else {
            this.radioDate = null;
          }
          this.dateField = ''
          break;
        case 'status':
          if (this.radioStatus === null) {
            this.radioStatus = 'status';
          } else {
            this.radioStatus = null;
          }
          this.statusField = ''
          break;
        case 'depositionId':
          if (this.radioID === null) {
            this.radioID = 'depositionId';
          } else {
            this.radioID = null;
          }
          this.nameField = ''
          break;
        case 'records':
          if (this.radioRecords === null) {
            this.radioRecords = 'records';
          } else {
            this.radioRecords = null;
          }
          this.recordsField = ''
          break;
        case 'errorDep':
          if (this.radioErrorDep === null) {
            this.radioErrorDep = 'errorDep';
            this.errorDepField = true
          } else {
            this.errorDepField = false
            this.radioErrorDep = null;
          }
          break;
        case 'submitter':
          if (this.radioSubmitter === null) {
            this.radioSubmitter = 'submitter';
          } else {
            this.radioSubmitter = null;
          }
          this.submitterField = ''
          break;
        case 'types':
          if (this.radioTypes === null) {
            this.radioTypes = 'types';
          } else {
            this.radioTypes = null;
          }
          this.typesField = ''
          break;
        case 'errorFile':
          if (this.radioErrorFile === null) {
            this.radioErrorFile = 'errorFile';
            this.errorFileField = true
          } else {
            this.errorFileField = false
            this.radioErrorFile = null;
          }
          break;
        default:
          break;
      }
    },
    clearFilters() {
      this.radioDatasources = null,
        this.radioTypes = null,
        this.radioDate = null,
        this.radioStatus = null,
        this.radioID = null,
        this.radioErrorDep = null,
        this.radioRecords = null,
        this.radioSubmitter = null
      this.radioErrorFile = null,

        this.datasourcesField = '',
        this.typesField = '',
        this.dateField = '';
      this.statusField = '',
        this.nameField = '',
        this.errorDepField = false,
        this.recordsField = null,
        this.submitterField = '',
        this.errorFileField = false
    },
    sortListHandler(clickedIcon) {
      this.icons.forEach(icon => {
        if (icon === clickedIcon) {
          icon.condition = !icon.condition;
        } else {
          icon.condition = null;
        }
      });
      this.sortList(clickedIcon.title, clickedIcon.condition);
    },
    setDate1(date) {
      return new Date(date).toLocaleString("en-GB", {
        year: 'numeric', month: '2-digit', day: '2-digit',
        hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false
      }).replace(', ', ' at ')
    },
    setDate2(date) {
      return new Date(date).toLocaleDateString("en-GB")
    },
  },
  watch: {
    activeStatus() {
      this.resetPage();
    },
    contents() {
      this.updatePaginatedContents();
    }
  },
  created() {
    this.$root.$on('reset-pagination', this.resetPage);
    this.updatePaginatedContents();
  },
  beforeDestroy() {
    this.$root.$off('reset-pagination', this.resetPage);
  }
};
</script>

<style lang="scss" scoped>
thead tr {
  background-color: #f2f2f2;
}

thead th {
  min-width: 170px
}

tbody {
  cursor: pointer
}

tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

.pagination button {
  margin: 0 3.5px;
  padding: 10px 15px;
  background-color: #fff;
  border: 1px solid #000;
  cursor: pointer;
}

.pagination button.active {
  background-color: #000;
  color: #fff;
}

.pagination button:hover {
  background-color: #888;
}

input[type="radio"] {
  accent-color: #000;
  transform: scale(1.8)
}

input[type="text"],
input[type="date"],
select,
option {
  width: 254px;
  height: 36px;
  border: 1px solid #A7A7A7;
  border-radius: 4px;
  padding: 12px
}

select {
  padding: 0 12px
}

.span1 {
  position: absolute;
  white-space: nowrap;
  padding: 4px;
  border-radius: 4px;
  bottom: -46px;
  left: 0px;
  background-color: white;
  transform: translateX(-2%);
  outline: 1px solid black;
  z-index: 2;
  transition: all 0.1s;
  //opacity: 0;
  pointer-events: none;
}

// quitar transform si modal está abierto

.span2 {
  position: absolute;
  white-space: nowrap;
  padding: 4px;
  border-radius: 4px;
  bottom: -46px;
  left: 0px;
  background-color: white;
  transform: translateX(-2%);
  z-index: 4;
  transition: all 0.1s;
  //opacity: 0;
  pointer-events: none;
}

.arrow {
  position: absolute;
  bottom: -2px;
  left: 6px;
  //transform: translateX(-50%)
  background: white;
  //display: inline-block;
  transition: all 0.1s;
  z-index: 3;
  //opacity: 0;
  pointer-events: none;
}

.arrow:after {
  content: "";
  position: absolute;
  border: 1px solid black;
  left: 4px;
  width: 15px;
  height: 15px;
  background: inherit;
  //margin: auto
  transform: rotate(45deg);
  clip-path: polygon(0 0, 0% 100%, 100% 0);
  z-index: 3;
}
</style>