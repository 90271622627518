import * as axios from 'axios';
import {
  axiosConfig,
  axiosError,
  parseResponse,
  parseSaveError,
  okResult,
  errorResult,
} from '@/api';

const WorkbenchMerckIndex = axios.create({
  baseURL: process.env.VUE_APP_MERCKINDEX_API,
});

WorkbenchMerckIndex.interceptors.request.use(axiosConfig, axiosError);

const getStatusWiseResults = async (searchQueryString) => {
  try {
    const response = await WorkbenchMerckIndex.get(`/statuswisemonographs?${searchQueryString}`);
    let data = parseResponse(response);
    const monographs = data;
    return okResult(monographs);
  } catch (error) {
    console.error(error);
    return errorResult(error);
  }
}

const getSearchResults = async (searchQueryString) => {
  try {
    const response = await WorkbenchMerckIndex.get(`/monographs?${searchQueryString}`);
    let data = parseResponse(response);
    const monographs = data;
    return okResult(monographs);
  } catch (error) {
    console.error(error);
    return errorResult(error);
  }
}

const getMonograph = async (articleId) => {
  try {
    const response = await WorkbenchMerckIndex.get(`/monograph/${articleId}`);
    let data = parseResponse(response);
    const monograph = data;
    return okResult(monograph);
  } catch (error) {
    console.error(error);
    return errorResult(error);
  }
}

const getContainer = async (recordId) => {
  try {
    const response = await WorkbenchMerckIndex.get(`/container/${recordId}`);
    let data = parseResponse(response);
    const container = data;
    return okResult(container);
  } catch (error) {
    console.error(error);
    return errorResult(error);
  }
}

const getUsers = async () => {
  try {
    const response = await WorkbenchMerckIndex.get('/users');
    let data = parseResponse(response);
    const users = data;
    return okResult(users);
  } catch (error) {
    console.error(error);
    return errorResult(error);
  }
}

const addNewMonograph = async (monograph) => {
  try {
    const response = await WorkbenchMerckIndex.post('/monograph', monograph);
    let data = parseResponse(response);
    const parsedResponse = data;
    return okResult(parsedResponse);
  } catch (error) {
    let errorResponse = parseSaveError(error);
    return errorResponse;
  }
}

const updateMonograph = async (monograph) => {
  try {
    const response = await WorkbenchMerckIndex.put(`/monograph/${monograph.articleId}`, monograph);
    let data = parseResponse(response);
    const parsedResponse = data;
    return okResult(parsedResponse);
  } catch (error) {
    let errorResponse = parseSaveError(error);
    return errorResponse;
  }
}

const updateMonographStatus = async (articleId, statusWithOptionalNote) => {
  try {
    const response = await WorkbenchMerckIndex.put(`/change-status?articleId=${articleId}`, statusWithOptionalNote);
    let data = parseResponse(response);
    const parsedResponse = data;
    return okResult(parsedResponse);
  } catch (error) {
    let errorResponse = parseSaveError(error);
    return errorResponse;
  }
}

const addOrUpdateContainer = async (formData) => {
  try {
    const response = await WorkbenchMerckIndex.post('/container', formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });
    let data = parseResponse(response);
    const containerData = data;
    return okResult(containerData);
  } catch (error) {
    let errorResponse = parseSaveError(error);
    return errorResponse;
  }
}

const deleteContainer = async (recordId) => {
  try {
    const response = await WorkbenchMerckIndex.delete(`/container/${recordId}`);
    let data = parseResponse(response);
    const parsedResponse = data;
    return okResult(parsedResponse);
  } catch (error) {
    let errorResponse = parseSaveError(error);
    return errorResponse;
  }
}

const DeleteImageSubstance = async (recordId) => {
  try {
    const response = await WorkbenchMerckIndex.delete(`/container/${recordId}/substance`);
    let data = parseResponse(response);
    const parsedResponse = data;
    return okResult(parsedResponse);
  } catch (error) {
    let errorResponse = parseSaveError(error);
    return errorResponse;
  }
}

const DeleteMolFile = async (recordId) => {
  try {
    const response = await WorkbenchMerckIndex.delete(`/container/${recordId}/mol`);
    let data = parseResponse(response);
    const parsedResponse = data;
    return okResult(parsedResponse);
  } catch (error) {
    let errorResponse = parseSaveError(error);
    return errorResponse;
  }
}

const DeleteCdxFile = async (recordId) => {
  try {
    const response = await WorkbenchMerckIndex.delete(`/container/${recordId}/cdx`);
    let data = parseResponse(response);
    const parsedResponse = data;
    return okResult(parsedResponse);
  } catch (error) {
    let errorResponse = parseSaveError(error);
    return errorResponse;
  }
}

export const MerckIndexDataService = {
  getStatusWiseResults,
  getSearchResults,
  getMonograph,
  getContainer,
  getUsers,
  addNewMonograph,
  updateMonograph,
  updateMonographStatus,
  addOrUpdateContainer,
  deleteContainer,
  DeleteImageSubstance,
  DeleteMolFile,
  DeleteCdxFile
}
