<template>

  <ValidationProvider tag="div" class="field" :rules="rules" v-slot="{ errors }">
      
    <label v-if="label" class="label" :for="id">
      <span v-html="label"></span>
      <RequiredAsterisk v-if="rules.includes('required')" />
    </label>

    <div class="control">
      
      <textarea
        class="textarea"
        autocomplete="off"
        :class="{ 'is-danger': !!errors.length }"
        :id="id"
        :value="value"
        :disabled="disabled"
        :maxlength="maxlength"
        :placeholder="placeholder"
        :aria-invalid="!!errors.length"
        :aria-errormessage="`${id}Err`"
        @input="updateValue($event.target.value)">
      </textarea>

      <!-- Validation message -->
      <p v-if="!!errors.length" class="help is-danger" :id="`${id}Err`">
        {{ errors[0] }}
      </p>

    </div>

  </ValidationProvider>

</template>

<script>
import { ValidationProvider } from 'vee-validate';
import RequiredAsterisk from '@/components/forms/required-asterisk';
export default {
  name: 'TextAreaElement',
  props: {
    id: {
      type: String,
      required: true,
      validator: value => { return value.length },
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    rules: {
      type: String,
      default: ''
    },
    maxlength: {
      type: Number,
      default: 4096 // Standard InChI can be very long
    },
    value: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    updateValue(value) {
      this.$emit('input', value);
    },
  },
  components: {
    RequiredAsterisk,
    ValidationProvider,
  },
};
</script>
