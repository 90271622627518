<template>
  <transition name="fade">
    <p v-if="error" class="box has-text-danger">
      Couldn't fetch compound {{ recordId }} ({{ error }})
    </p>
    <div v-else-if="compound" class="box">
      <div class="columns is-mobile">
        <div class="column">
          <h2 v-html="compound.title" class="is-size-5 mb-3"></h2>
          <p><b>Compound ID:</b> {{ compound.marinLitId }}</p>
          <p><b>Molecular formula:</b> <span v-html="compound.molecularFormula"></span></p>
          <p><b>Exact mass:</b> {{ compound.molecularWeight }}</p>
          <p><b>Status:</b> {{ compound.status }}</p>
          <p v-if="compound.note">Compound {{ compound.note }} in original article</p>
        </div>
        <div class="column">
          <img class="image" :src="imageUrl" alt="" />
        </div>
      </div>
      <div v-if="showButtons" class="columns is-mobile is-centered">
        <div class="column">
          <button type="button" class="button is-danger" @click="$emit('delete')" :disabled="isArticleReadOnly">
            <span class="icon"><i class="fas fa-trash-alt"></i></span>
            <span>Delete</span>
          </button>
        </div>
        <div class="column has-text-right">
          <button type="button" class="button is-link" @click="$emit('edit')">
            <span class="icon"><i class="fas" :class="isArticleReadOnly ? 'fa-file' : 'fa-pen'"></i></span>
            <span>{{ isArticleReadOnly ? 'Details' : 'Edit' }}</span>
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { MarinLitDataService } from '@/api';
export default {
  name: 'CompoundCard',
  // Compound cards on the 'Compounds' and 'Preview' tabs are
  // responsible for fetching their own static data for display
  async mounted() {
    const result = await MarinLitDataService.getCompound(this.recordId);
    if (result.Data && result.Data.marinLitId) {
      this.compound = result.Data;
    }
    else {
      this.error = result.ServiceStatus.Error;
    }
  },
  updated() {
    if (this.compound) {
      // Destructure the compound object into properties required for
      // 'Copy locations from another compound' dropdown list and 
      // article preview - then pass them up to the parent component
      const { title, marinLitId, locations } = this.compound;
      this.$emit('emit-locations', { title, marinLitId, locations });
    }
  },
  data() {
    return {
      compound: null,
      error: null,
    }
  },
  computed: {
    imageUrl() {
      return this.compound && this.compound.pngFilePath
        ? `${process.env.VUE_APP_MARINLIT_FILES}/${this.compound.pngFilePath}`
        : null;
    },
  },
  props: {
    recordId: {
      type: String,
      required: true,
    },
    showButtons: {
      type: Boolean,
      default: false,
    },
    isArticleReadOnly: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
