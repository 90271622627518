<template>
  <div>

    <div v-if="!isArticleReadOnly" class="block">
      <button id="btn-discard-article" type="button" class="button is-danger" @click="toggleDiscardArticlePrompt" :disabled="isFormLocked">
        <span class="icon"><i class="fas fa-trash-alt"></i></span>
        <span>Discard article</span>
      </button>
      <ModalWindow
        :isOpen="isDiscardArticlePromptVisible"
        :heading="`Discard ${article.marinLitId ? article.marinLitId : 'new article'}`"
        :disabled="isFormSaving"
        @close="toggleDiscardArticlePrompt"
      >
        <template #body>
          <p>Do you really want to discard this article?</p>
          <p>This action is permanent and irreversible.</p>
        </template>
        <template #footer>
          <button
            type="button"
            class="button is-danger"
            :class="{ 'is-loading': isFormSaving }"
            @click="discardArticle"
          >Discard</button>
        </template>
      </ModalWindow>
    </div>

    <MessageBlock v-if="saveError" heading="Failed to save article" class="is-danger">
      <p>{{ saveError }}</p>
    </MessageBlock>

    <ValidationObserver tag="div" :ref="formObserver" v-slot="{ handleSubmit }">

      <form @submit.prevent="handleSubmit(saveArticle)">

        <fieldset :disabled="isFormLocked">
          
          <!-- ROW 1 (Article title) -->
          <div class="columns is-mobile">
            <div class="column is-2">
              <label class="label">Article title<RequiredAsterisk /></label>
            </div>
            <div class="column is-10">
              <ValidationProvider rules="html_required:title" vid="title" tag="div" class="field" v-slot="{ errors }">
                <div class="control" :class="{ 'is-danger': !!errors.length }">
                  <HtmlEditor id="title" :init="TinyMceConfig" v-model="title" />
                  <p v-if="!!errors.length" class="help is-danger">{{ errors[0] }}</p>
                </div>
              </ValidationProvider>
            </div>
          </div>

          <!-- ROW 2 (Authors) -->
          <div class="columns is-mobile mb-1" v-for="(author, index) in authors" :key="index">
            <div v-if="index === 0" class="column is-2">
              <label class="label">Author(s)</label>
            </div>
            <div class="column is-4" :class="{ 'is-offset-2': index > 0 }">
              <TextInput :id="`firstName${index}`" v-model="author.firstName" placeholder="Given name" />
            </div>
            <div class="column is-4">
              <TextInput :id="`lastName${index}`" v-model="author.lastName" placeholder="Family name" />
            </div>
            <div class="column is-2">
              <button v-if="index === 0" type="button" id="btn-add-author" class="button is-link is-outlined is-fullwidth" @click="addAuthorRowAction">
                <span class="icon"><i class="fas fa-plus"></i></span>
                <span>Add author</span>
              </button>
              <button v-else type="button" :id="`btn-remove-author-${index}`" class="button is-outlined is-fullwidth" @click="deleteAuthorRow(index)">
                <span class="icon"><i class="fas fa-trash-alt"></i></span>
                <span>Remove</span>
              </button>
            </div>
          </div>

          <!-- ROW 3 (Journal and DOI) -->
          <div class="columns is-mobile mt-1">
            <div class="column is-2">
              <label for="journalTitle" class="label">Journal</label>
            </div>
            <div class="column is-4">
              <TextInput id="journalTitle" v-model="journalTitle" placeholder="Journal" />
            </div>
            <div class="column is-narrow is-offset-1">
              <label for="doi" class="label">DOI</label>
            </div>
            <div class="column">
              <TextInput id="doi" rules="doi" v-model.trim="doi" placeholder="DOI" />
            </div>
          </div>

          <!-- ROW 4 (Volume, Issue, Year and Page range) -->
          <div class="columns is-mobile">
            <div class="column is-2">
              <label for="journalVolume" class="label">Volume</label>
            </div>
            <div class="column is-1">
              <TextInput id="journalVolume" v-model.trim="journalVolume" placeholder="Volume" />
            </div>
            <div class="column is-1 is-offset-1 has-text-right">
              <label for="issueNumber" class="label">Issue</label>
            </div>
            <div class="column is-1">
              <TextInput id="issueNumber" v-model.trim="issueNumber" placeholder="Issue" />
            </div>
            <div class="column is-1 has-text-right">
              <label for="subsYear" class="label">Year</label>
            </div>
            <div class="column is-1">
              <TextInput id="subsYear" v-model.trim="subsYear" :maxlength="4" placeholder="Year" />
            </div>
            <div class="column is-2 has-text-right">
              <label class="label">Page range</label>
            </div>
            <div class="column is-1">
              <TextInput id="firstPage" v-model.trim="firstPage" placeholder="First" />
            </div>
            <div class="column is-1">
              <TextInput id="lastPage" v-model.trim="lastPage" placeholder="Last" />
            </div>
          </div>
          <NextPreviousButtons v-if="!isArticleReadOnly" next="Classification" :showThrobber="isFormSaving">
            <template #prev>
              <button type="button" @click="$router.push({ name: 'MarinLit' })" class="button">
                <span class="icon"><i class="fas fa-backward"></i></span>
                <span>Back to article list</span>
              </button>
            </template>
          </NextPreviousButtons>
        </fieldset>
      </form>

    </ValidationObserver>

  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { mapFields, mapMultiRowFields } from 'vuex-map-fields';
import ArticleTabsMixin from '@/mixins/marinlit/article-tabs-mixin';
export default {
  name: 'TabBasic',
  data() {
    return {
      isDiscardArticlePromptVisible: false,
    }
  },
  mixins: [ArticleTabsMixin],
  computed: {
    ...mapFields('MarinLitEditor', [
      'article.title',
      'article.journalTitle',
      'article.doi',
      'article.journalVolume',
      'article.issueNumber',
      'article.subsYear',
      'article.firstPage',
      'article.lastPage',
    ]),
    ...mapMultiRowFields('MarinLitEditor', [
      'article.authors',
    ]),
  },
  methods: {
    ...mapActions('MarinLitEditor', [
      'addAuthorRowAction',
      'deleteMultiRowValueAction',
    ]),
    // Override the mixin because VeeValidate 3's handleSubmit function
    // discards the native form submission, including e.submitter.value,
    // so we have to provide 'Classification' (next tab) manually.
    async saveArticle() {
      this.$emit('saveArticle', 'Classification');
    },
    async discardArticle() {
      this.$emit('discardArticle');
    },
    toggleDiscardArticlePrompt() {
      this.isDiscardArticlePromptVisible = !this.isDiscardArticlePromptVisible;
    },
    deleteAuthorRow(index) {
      this.deleteMultiRowValueAction({ key: 'authors', index });
    },
  },
};
</script>
