<template>

  <ValidationProvider tag="div" class="field" :rules="rules" v-slot="{ errors }">
    
    <label v-if="label" class="label" :for="id">
      <span v-html="label"></span>
      <RequiredAsterisk v-if="rules.includes('required')" />
    </label>
  
    <div class="control">
    
      <div class="select is-fullwidth" :class="{ 'is-danger': !!errors.length }">

        <select :id="id" v-model="model">
          <option disabled value="">Select...</option>
          <option v-for="option in options" :value="option" :key="option">
            {{ option }}
          </option>
        </select>

      </div>

    </div>

    <p v-if="!!errors.length" class="help is-danger">{{ errors[0] }}</p>

  </ValidationProvider>

</template>

<script>
import { ValidationProvider } from 'vee-validate';
import RequiredAsterisk from '@/components/forms/required-asterisk';
export default {
  name: 'SelectElement',
  props: {
    id: {
      type: String,
      required: true,
      validator: value => { return value.length },
    },
    label: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      required: true
    },
    rules: {
      type: String,
      default: ''
    },
    errorMsg: {
      type: String,
      default: 'Not valid'
    },
    value: {
      type: [String, Number],
      default: ''
    },
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
        this.$emit('change', value);
      }
    }
  },
  components: {
    RequiredAsterisk,
    ValidationProvider,
  },
};
</script>
