<template>
  <div class="message">
    <div class="message-body content">
      <p v-if="heading"><b>{{ heading }}</b></p>
      <slot></slot>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'MessageBlock',
    props: {
      heading: {
        type: String,
        default: '',
      },
    },
  }
</script>
