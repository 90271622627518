<template>
  <div class="buttons has-addons are-large mb-6">
    <button
      type="button"
      class="button is-status"
      v-for="s in statusWiseRecords"
      @click="updateStatus(s.value)"
      :disabled="$store.state.articleStateCounter !== 0"
      :key="s.value"
      :id="`btn-status-${s.value}`"
      :class="{ 'is-link is-selected': s.value === selectedStatus, 'is-loading': isLoading }">
      <animated-integer v-if="!isLoading" :id="`status-${s.value}-number`" class="has-text-link" :value="s.count" />
      <br />
      <span class="is-size-6">{{ s.name }}</span>
    </button>
  </div>
</template>

<script>
import { AnimatedInteger } from '@/components/shared';
import { mapFields } from 'vuex-map-fields';
export default {
  name: 'StatusLinks',
  props: {
    statusWiseRecords: {
      type: Array,
      default: () => []
    },
  },
  computed: {
    isLoading() {
      return this.statusWiseRecords.every(obj => !Object.keys(obj).length);
    },
    selectedStatus() {
      // Get the selected status from the current product's Vuex module
      return this[`Status${this.$route.name}`];
    },
    ...mapFields('MerckIndexSearch', {
      StatusMerckIndex: 'SearchParams.status.value'
    }),
    ...mapFields('MarinLitSearch', {
      StatusMarinLit: 'SearchParams.status.value'
    }),
  },
  methods: {
    updateStatus(status) {
      // Set the selected status in the current product's Vuex module
      this[`Status${this.$route.name}`] = status;
      this.$emit('click', true);
    },
  }, 
  components: {
    AnimatedInteger,
  },
}
</script>

<style lang="scss" scoped>
button.is-status {
  margin: 0;
  padding: 0;
  height: 3em;
  flex-grow: 1;
  flex-basis: 0;
  display: block;
  line-height: 1.05;
  &.is-selected span {
    color: white !important;
  }
}
</style>