<template>

  <div>

    <!-- Labels for text inputs with add-ons must be placed before the field -->
    <!-- container in order to appear above the input, rather than inline.   -->
    <label v-if="label && hasAddons" class="label" :for="id" v-html="label" />
  
    <ValidationProvider
      tag="div"
      :vid="id"
      class="field"
      :class="{ 'has-addons': hasAddons }"
      :rules="rules"
      v-slot="{ errors }">
        
      <label v-if="label && !hasAddons" class="label" :for="id">
        <span v-html="label"></span>
        <RequiredAsterisk v-if="rules.includes('required')" />
      </label>

      <div class="control" :class="{ 'is-expanded': hasAddons }">
        
        <!-- Prevent Enter key from submitting forms, but allow custom events -->
        <input
          type="text"
          class="input"
          autocomplete="off"
          :class="{ 'is-danger': !!errors.length }"
          :id="id"
          :value="value"
          :disabled="disabled"
          :maxlength="maxlength"
          :placeholder="placeholder"
          :aria-invalid="!!errors.length"
          :aria-errormessage="`${id}Err`"
          @input="updateValue($event.target.value)"
          @keydown.enter.prevent="customKeydownEnter && value && !errors.length
            ? $emit('keydown-enter')
            : null"
        />

        <!-- Validation message -->
        <p v-if="!!errors.length" class="help is-danger" :id="`${id}Err`">
          {{ errors[0] }}
        </p>

      </div>

      <slot name="addons" />

    </ValidationProvider>

  </div>

</template>

<script>
import { ValidationProvider } from 'vee-validate';
import RequiredAsterisk from '@/components/forms/required-asterisk';
export default {
  name: 'TextInputElement',
  props: {
    id: {
      type: String,
      required: true,
      validator: value => { return value.length },
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    rules: {
      type: String,
      default: ''
    },
    maxlength: {
      type: Number,
      default: 255
    },
    value: {
      type: [String, Number],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    customKeydownEnter: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    hasAddons() {
      return !!this.$slots.addons;
    },
  },
  methods: {
    updateValue(value) {
      this.$emit('input', value);
    },
  },
  components: {
    RequiredAsterisk,
    ValidationProvider,
  },
};
</script>
