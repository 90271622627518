<template>
  <div v-if="isOpen" class="modal is-active">
    <div class="modal-background" @click="close"></div>
    <fieldset class="modal-card" :disabled="disabled">
      <header v-if="heading" class="modal-card-head" >
        <p class="modal-card-title modal-limited-to-page-width">{{ heading }}</p>
      </header>
      <section class="modal-card-body" :class="{ 'no-header': !heading, 'no-footer': !hasFooter }">
        <slot name="body"></slot>
      </section>
      <footer v-if="hasFooter" class="modal-card-foot">
        <slot name="footer"></slot>
        <button v-if="showCancelButton" type="button" class="button" @click="close">Cancel</button>
      </footer>
    </fieldset>
  </div>
</template>

<script>
export default {
  name: 'ModalWindow',
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    heading: {
      type: String,
      default: '',
    },
    showCancelButton: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    hasFooter() {
      return !!this.$slots.footer;
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
}
</script>

<style lang="scss" scoped>
.no-header {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.no-footer {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
.modal-limited-to-page-width {  
  width: 100%;
}
</style>