<template>
  <div>
    <!-- The content in this component should loosely resemble the -->
    <!-- layout of the article record page on the MarinLit website -->
    <div class="content">
    
      <!-- Title (required) -->
      <h2 v-html="article.title" class="mb-5" id="article-preview-title"></h2>

      <!-- Authors -->
      <div v-if="hasAuthors" class="block" id="article-preview-authors">
        <h4 class="mb-3">Authors</h4>
        <p><ArticleAuthors :authors="article.authors" /></p>
      </div>
      
      <!-- Citation -->
      <div v-if="article.journalTitle || article.doi" class="block" id="article-preview-citation">
        <h4 class="mb-3">Citation</h4>
        <p>
          <template v-if="article.journalTitle">
            <ArticleCitation :article="article" /><br />
          </template>
          <ArticleDoiLink v-if="article.doi" :doi="article.doi" />
        </p>
      </div>

      <!-- Trivial names -->
      <div v-if="!!article.trivialNames.length" class="block" id="article-preview-trivial-names">
        <h4 class="mb-3">Trivial names</h4>
        <p>{{ this.formattedTrivialNames }}</p>
      </div>
      
      <!-- Note -->
      <div v-if="article.note" class="block" id="article-preview-note">
        <h4 class="mb-3">Note</h4>
        <p v-html="article.note"></p>
      </div>

      <!-- Categories -->
      <div v-if="article.categoriesDisplay" class="block" id="article-preview-categories">
        <h4 class="mb-3">Categories</h4>
        <p>{{ article.categoriesDisplay }}</p>
      </div>

    </div>

    <div v-if="this.visibleTabs.length" id="article-preview-tabs">
      
      <div class="tabs is-boxed">
        <ul>
          <li v-for="tab in visibleTabs" :key="tab.id" :class="{ 'is-active': currentTab === tab.id }">
            <a href @click.prevent="currentTab = tab.id" :id="`article-preview-tab-${tab.id}`">{{ tab.title }}</a>
          </li>
        </ul>
      </div>
      
      <div :id="`article-preview-${currentTab}`">
        
        <!-- Compounds -->
        <div v-show="currentTab === 'compounds'" class="columns is-mobile is-multiline">
          <div v-for="recordId in compoundsByMarinLitId" :key="recordId" class="column is-half">
            <CompoundCard :recordId="recordId" @emit-locations="updateCompoundLocations" />
          </div>
        </div>

        <!-- Taxonomy -->
        <div v-show="currentTab === 'taxonomy'" class="table-container">
          <table class="table is-bordered is-fullwidth taxonomy-table">
            <thead>
              <tr>
                <th>Phylum</th>
                <th>Class</th>
                <th>Order</th>
                <th>Family</th>
                <th>Genus</th>
                <th>Species</th>
                <th>Source of compound</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(taxonomy, index) in article.taxonomies" :key="`taxonomy${index}`">
                <td>{{ taxonomy.phylum }}</td>
                <td>{{ taxonomy.class }}</td>
                <td>{{ taxonomy.order }}</td>
                <td>{{ taxonomy.family }}</td>
                <td>{{ taxonomy.genus }}</td>
                <td>{{ taxonomy.species }}</td>
                <td>{{ taxonomy.isSourceOfCompound ? 'Yes' : '' }}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- Location -->
        <div v-show="currentTab === 'location'" class="table-container">
          <table v-if="locations.length" class="table is-bordered is-fullwidth">
            <thead>
              <tr>
                <th>Location name/site</th>
                <th>Latitude</th>
                <th>Longitude</th>
                <th>Depth (m)</th>
                <th>Location QF</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(loc, index) in locations" :key="`location${index}`">
                <td>{{ loc.name }}</td>
                <td>{{ loc.location.lat }}</td>
                <td>{{ loc.location.lon }}</td>
                <td>{{ loc.depth }}</td>
                <td>{{ loc.qf }}</td>
              </tr>
            </tbody>
          </table>
          <p v-else>None of the compounds in this article have locations.</p>
        </div>

        <!-- Related articles -->
        <div v-show="currentTab === 'related'">
          <ArticleCard
            v-for="articleId in article.relatedArticles"
            :key="articleId"
            :articleId="articleId"
            :showButtons="false"
          />
        </div>

      </div>

    </div>

  </div>
</template>

<script>
import ArticleCard from '@/components/marinlit/shared/article-card';
import CompoundCard from '@/components/marinlit/shared/compound-card';
import { ArticleAuthors, ArticleCitation, ArticleDoiLink } from '@/components/shared';

export default {
  name: 'ArticlePreview',
  data() {
    return {
      currentTab: '',
      tabs: [
        {
          id: 'compounds',
          title: 'Compound data',
          visible: this.compounds.length || this.article.substances && !!this.article.substances.length,
        },
        {
          id: 'taxonomy',
          title: 'Taxonomy',
          visible: this.article.taxonomies && !!this.article.taxonomies.length,
        },
        {
          id: 'location',
          title: 'Location',
          visible: this.compounds.length || this.article.substances && !!this.article.substances.length,
        },
        {
          id: 'related',
          title: 'Related articles',
          visible: this.article.relatedArticles && !!this.article.relatedArticles.length,
        },
      ],
      locations: [],
    }
  },
  props: {
    article: {
      type: Object,
      required: true,
    },
    compounds: {
      type: Array,
      default: () => []
    },
  },
  mounted() {
    this.currentTab = this.visibleTabs.length ? this.tabs.find(tab => tab.visible).id : '';
  },
  computed: {
    visibleTabs() {
      return this.tabs.filter(tab => tab.visible);
    },
    hasAuthors() {
      return Object.values(this.article.authors).some(a => (a.firstName || a.lastName));
    },
    compoundsByMarinLitId() {
      // DS-2964 Preview from article list provides compounds (prop) ordered by MarinLit ID
      return this.compounds.length ? this.compounds : this.article.substances;
    },
    formattedTrivialNames() {
      return this.article.trivialNames ? this.article.trivialNames.join(', ') : '';
    },
  },
  methods: {
    updateCompoundLocations(compound) {
      // DS-3143 Compound location data is fetched and emitted asyncronously by each compound 
      // card, so collect it all in this component for rendering in the 'Location' tab
      this.locations.push(...compound.locations);
    },
  },
  components: {
    ArticleCard,
    CompoundCard,
    ArticleAuthors,
    ArticleCitation,
    ArticleDoiLink,
  },
}
</script>
