import Vue from 'vue';
import Vuex from 'vuex';
import Auth from './modules/auth';
import MarinLitEditor from './modules/marinlit/editor';
import MarinLitImporter from './modules/marinlit/importer';
import MarinLitSearch from './modules/marinlit/search';
import MerckIndexSearch from './modules/merckindex/search';

Vue.use(Vuex)

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    Auth,
    MarinLitEditor,
    MarinLitImporter,
    MarinLitSearch,
    MerckIndexSearch,
  },
  state: {
    articleStateCounter: 0
  },
  mutations:{
    addArticleStateCounter: function () {
      this.state.articleStateCounter++;
    },
    subArticleStateCounter: function () {
      this.state.articleStateCounter--;
    },
    resetArticleStateCounter: function () {
      this.state.articleStateCounter = 0;
    },
  }
});
