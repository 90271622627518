<template>
  <div>
    
    <StatusLinks id="region-status-links" :statusWiseRecords="statusWiseRecords" @click="updateSearchResults" />

    <ValidationObserver tag="div" class="columns is-mobile" v-slot="{ errors }">
      
      <!-- SEARCH -->
      <div id="region-sidebar" class="column is-one-third">
        
        <h2 class="title list-page-heading">Search</h2>
        
        <!-- Metadata -->
        <TogglePanel heading="Metadata" id="box-search-metadata" expanded>
          <label class="label" for="createdBy">Created by</label>
          <v-select v-model="createdBy" placeholder="Select user..." inputId="createdBy" :options="users" @input="updateAll" class="mb-4" />
          <label class="label" for="lastModifiedBy">Last modified by</label>
          <v-select v-model="lastModifiedBy" placeholder="Select user..." inputId="lastModifiedBy" :options="users" @input="updateAll" class="mb-4" />
          <label class="label">Published date</label>
          <div class="columns is-mobile is-gapless is-vcentered mb-4">
            <div class="column"><input type="date" :min="minDate" :max="today" v-model="datePublishedFrom" @input="updateAll" /></div>
            <div class="column has-text-centered">to</div>
            <div class="column"><input type="date" :min="minDate" :max="today" v-model="datePublishedTo" @input="updateAll" /></div>
          </div>
          <label class="label">Created date</label>
          <div class="columns is-mobile is-gapless is-vcentered mb-4">
            <div class="column"><input type="date" :min="minDate" :max="today" v-model="dateCreatedFrom" @input="updateAll" /></div>
            <div class="column has-text-centered">to</div>
            <div class="column"><input type="date" :min="minDate" :max="today" v-model="dateCreatedTo" @input="updateAll" /></div>
          </div>
          <label class="label">Last modified date</label>
          <div class="columns is-mobile is-gapless is-vcentered mb-4">
            <div class="column"><input type="date" :min="minDate" :max="today" v-model="dateLastModifiedFrom" @input="updateAll" /></div>
            <div class="column has-text-centered">to</div>
            <div class="column"><input type="date" :min="minDate" :max="today" v-model="dateLastModifiedTo" @input="updateAll" /></div>
          </div>
        </TogglePanel>
        
        <!-- Article -->
        <TogglePanel heading="Article" id="box-search-article">
          <TextInput id="articleFreeText" v-model="articleFreeText" label="Title / trivial name" placeholder="Enter your search text" @input="debouncedSearch" class="mb-4" />
          <TextInput id="marinLitId" v-model.trim="marinLitId" rules="articleId" label="MarinLit ID" placeholder="e.g. A23744" @input="debouncedSearch" class="mb-4" />
          <TextInput id="doi" v-model.trim="doi" rules="doi" label="DOI" placeholder="e.g. 10.1039/D0RA00894J" @input="debouncedSearch" class="mb-4" />
        </TogglePanel>
        
        <!-- Compound -->
        <TogglePanel heading="Compound" id="box-search-compound">
          <TextInput id="compoundName" v-model="compoundName" label="Name" placeholder="Enter your search text" @input="debouncedSearch" class="mb-4" />
          <TextInput id="inchiKey" v-model.trim="inchiKey" rules="inchikey" label="InChIKey" placeholder="e.g. CWQXZHGXSAZDMP-MHBSPTNUSA-N" @input="debouncedSearch" class="mb-4" />
          <TextInput id="compoundMarinLitId" v-model.trim="compoundMarinLitId" rules="compoundMarinLitId" label="MarinLit ID" placeholder="e.g. L31124" @input="debouncedSearch" class="mb-4" />
          <label class="label" for="compoundStatus">Compound status</label>
          <v-select v-model="compoundStatus" :searchable="false" placeholder="Select..." inputId="compoundStatus" :options="compoundStatusOptions" @input="updateAll" class="mb-4" />
        </TogglePanel>

        <h2 class="title is-4 mt-6">Reports</h2>

        <TogglePanel heading="Articles" id="box-report-articles">
          <label class="label">Published date</label>
          <div class="columns is-mobile is-gapless is-vcentered mb-4">
            <div class="column"><input type="date" :min="minDate" :max="today" v-model="reportArticlesDatePublishedFrom" /></div>
            <div class="column has-text-centered">to</div>
            <div class="column"><input type="date" :min="minDate" :max="today" v-model="reportArticlesDatePublishedTo" /></div>
          </div>
          <button type="button" @click="getArticleReport" id="btn-export-report-articles" class="button is-link is-outlined is-fullwidth mb-3" :disabled="!articleReportDateRangeQueryString">
            Export XLSX
          </button>
        </TogglePanel>

        <TogglePanel heading="Marine Natural Products Annual Review" id="box-report-annual-review" @on-expanded="onAnnualReviewToggled">
          <label class="label">Year published</label>
          <div class="columns is-mobile is-gapless is-vcentered mb-4">
            <div class="column"><input type="number" v-model="annualReviewYear" /></div>
            <div class="column has-text-centered">&nbsp;</div>
            <div class="column">
              <button type="button" @click="exportAnnualReview" id="btn-export-annual-review" class="button is-link is-outlined is-fullwidth" :disabled="!annualReviewYear">
                Run export job
              </button>
            </div>
          </div>
          
          <div class="columns is-mobile is-gapless is-vcentered mb-4">
            <ul v-if="annualReviews">
              <li v-for="(fileName, index) in annualReviews" :key="index" >
                <span v-if="fileName.endsWith('.part')">{{fileName}} (in progress)</span>
                <a v-else href="#" @click="getAnnualReview(fileName)">{{fileName}}</a>
              </li>
            </ul>
          </div>
        </TogglePanel>

      </div>

      <!-- ARTICLES -->
      <div id="region-content" class="column is-two-thirds">
        
        <CreateNewArticle v-if="isCreateArticleWindowOpen" @close="resetCreateArticleWindow" />
        
        <button type="button" id="btn-create-new-article" @click="isCreateArticleWindowOpen = true" class="button is-link is-outlined is-medium is-pulled-right">
          Create new article
        </button>
        
        <h2 class="title list-page-heading">
          <span id="number-article-list-heading"> {{ numberResultsListHeading }} </span>
          <span id="text-article-list-heading">{{ searchResultsListHeading }}</span>
        </h2>
        
        <!-- Search pills -->
        <div v-if="Object.keys(getSearchPills).length" class="tags are-medium">
          <SearchPill
            v-for="(param, id) in getSearchPills"
            :key="`${id}Pill`"
            :param="param"
            :errors="errors[id]"
            @removeParameter="removeSearchParameter(id)" />
        </div>
        
        <!-- Loading error -->
        <MessageBlock v-if="searchResultsError" class="is-danger">
          <p>There was a problem fetching the article list. Try refreshing the page.</p>
        </MessageBlock>
        
        <!-- Article list -->
        <ArticleResult v-for="article in searchResults" :key="article.articleId" :article="article" @disable="disableListButtons" @update="updateByResult"/>

      </div>
      
    </ValidationObserver>
    
  </div>
</template>

<script>
import { parse as parseDisposition } from 'content-disposition';
import { mapFields } from 'vuex-map-fields';
import { mapActions, mapGetters } from 'vuex';
import { MarinLitDataService as dataService } from '@/api';
import { ArticleResult, CreateNewArticle } from '@/components/marinlit/list';
import ArticleDataMixin from '@/mixins/marinlit/article-data-mixin';
import ListPageMixin from '@/mixins/shared/list-page-mixin';

export default {
  name: 'MarinLitList',
  metaInfo: {
    title: 'MarinLit'
  },
  mixins: [ArticleDataMixin, ListPageMixin],
  beforeRouteLeave(to, from, next) {
    this.clearArticleSearchResultsAction();
    next();
  },
  data() {
    return {
      isCreateArticleWindowOpen: false,
      minDate: '2014-01-01',
      reportArticlesDatePublishedFrom: '',
      reportArticlesDatePublishedTo: '',
      annualReviewYear: '',
      annualReviews: [],
      polling: null,      
    }
  },
  computed: {
    ...mapFields('MarinLitSearch', {
      users: 'UserList',
      compoundStatusOptions: 'CompoundStatusList',
      status: 'SearchParams.status.value',
      createdBy: 'SearchParams.createdBy.value',
      lastModifiedBy: 'SearchParams.lastModifiedBy.value',
      datePublishedFrom: 'SearchParams.datePublishedFrom.value',
      datePublishedTo: 'SearchParams.datePublishedTo.value',
      dateCreatedFrom: 'SearchParams.dateCreatedFrom.value',
      dateCreatedTo: 'SearchParams.dateCreatedTo.value',
      dateLastModifiedFrom: 'SearchParams.dateLastModifiedFrom.value',
      dateLastModifiedTo: 'SearchParams.dateLastModifiedTo.value',
      articleFreeText: 'SearchParams.articleFreeText.value',
      marinLitId: 'SearchParams.marinLitId.value',
      doi: 'SearchParams.doi.value',
      compoundName: 'SearchParams.compoundName.value',
      inchiKey: 'SearchParams.inchiKey.value',
      compoundMarinLitId: 'SearchParams.compoundMarinLitId.value',
      compoundStatus: 'SearchParams.compoundStatus.value',
    }),
    ...mapGetters('MarinLitSearch', [
      'getSearchPills',
      'getSearchQueryString',
      'getSearchQueryStringWithoutStatus',
    ]),
    articleReportDateRangeQueryString() {
      if (this.reportArticlesDatePublishedFrom <= this.reportArticlesDatePublishedTo
          && this.reportArticlesDatePublishedFrom >= this.minDate
          && this.reportArticlesDatePublishedFrom <= this.today
          && this.reportArticlesDatePublishedTo >= this.minDate
          && this.reportArticlesDatePublishedTo <= this.today) {
        return `publishedFrom=${this.reportArticlesDatePublishedFrom}&publishedTo=${this.reportArticlesDatePublishedTo}`;
      }
      else {
        return null;
      }
    },
  },
  watch: {
    status: function () {
      this.$store.commit('resetArticleStateCounter');
    }
  },
  methods: {
    ...mapActions('MarinLitImporter', [
      'clearArticleSearchResultsAction',
    ]),
    disableListButtons(){
      let elements = document.getElementsByClassName('button');      
      for(let i = 0; i < elements.length; i++) {
          elements[i].disabled = true;
      }      
    },
    updateByResult(){      
      this.updateAll()
    },
    async resetCreateArticleWindow(reload) {
      this.isCreateArticleWindowOpen = false;
      this.clearArticleSearchResultsAction();
      // Used after saving multiple new drafts
      if (reload) {
        await this.updateAll();
      }
    },
    async getArticleReport() {
      const result = await dataService.getArticleReport(this.articleReportDateRangeQueryString);
      if (result.Data) {
        // It's 2021 and this is how we download a file from an XHR request
        const link = document.createElement('a');
        const disposition = result.Data.headers['content-disposition'];
        const fileName = parseDisposition(disposition).parameters.filename;
        const url = window.URL.createObjectURL(new Blob([result.Data.data]));
        link.href = url;
        link.download = fileName;
        link.click();
        window.URL.revokeObjectURL(url);
      }
      else {
        window.alert(result.ServiceStatus.Error);
      }
    },
    async exportAnnualReview() {
      const year = this.annualReviewYear;
      this.annualReviewYear = '';
      const result = await dataService.requestAnnualReviewExport(year);
      if (!result.ServiceStatus.HasError) {
        window.alert("The request has been sent. Please allow a few minutes for the report to be generated.");
      }
      else {
        window.alert(result.ServiceStatus.Error);
      }
    },
    async refreshAnnualReviews() {
      const result = await dataService.getAnnualReviews();
      if (result.Data) {
        this.annualReviews = result.Data;
      }
      else {
        this.annualReviews = [];
        clearInterval(this.polling);
        window.alert(result.ServiceStatus.Error);
      }
    },
    async getAnnualReview(fileName) {
      const result = await dataService.getAnnualReview(fileName);
      if (result.Data) {
        const link = document.createElement('a');
        const url = window.URL.createObjectURL(new Blob([result.Data.data]));
        link.href = url;
        link.download = fileName;
        link.click();
        window.URL.revokeObjectURL(url);
      }
      else {
        window.alert(result.ServiceStatus.Error);
      }
    },
    async pollData() {
      this.polling = setInterval(async () => {
        await this.refreshAnnualReviews();
      }, 10000)
    },
    async onAnnualReviewToggled(isExpanded) {
      if (isExpanded) {
        await this.refreshAnnualReviews()
        this.pollData();
      } else {
        clearInterval(this.polling);
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.polling);
  },
  components: {
    ArticleResult,
    CreateNewArticle,
  }
}
</script>
