<template>
  <section class="depositions">
    <DepositionsHeader :title="datasource.Name ? `Data source: ${datasource.Name}` : (datasource.CompanyName ? `Data source: ${datasource.CompanyName}` : '')" />
    <div v-if="loading">
      <Loading />
    </div>
    <div>
      <div class="datasource-main-info">
        <div v-if="logo" class="datasource-logo">
          <img :src="logo" :alt="`${datasource.Name}-logo`">
        </div>
        <div v-if="datasource.CompanyName || datasource.Name" class="datasource-name">
          <h4>Datasource ID:</h4>
          <div class="datasource-id-wrapper">
            <p>{{ datasourceId }}</p>
            <CopyButton :text="datasourceId" />
          </div>
        </div>
        <div v-if="datasource.CompanyName || datasource.Name" class="datasource-name">
          <h4>Company name:</h4>
          <p>{{ datasource.CompanyName ?? datasource.Name }}</p>
        </div>
        <div v-if="datasource.Description" class="datasource-description">
          <h4>Description:</h4>
          <div v-html="decodedDescription"></div>
        </div>
      </div>
      <div class="datasource-contact-info">
        <h3>Company contacts</h3>
        <div v-if="datasource.StreetAddress">
          <h4>Address:</h4>
          <p>{{ datasource.StreetAddress }}</p>
          <p>{{ datasource.City }}</p>
          <p>{{ datasource.State }}</p>
          <p>{{ datasource.ZipCode }}</p>
          <p>{{ countryName }}</p>          
        </div>
        <div v-if="datasource.Phone">
          <h4>Phone number:</h4>
          <p>{{ datasource.Phone }}</p>
        </div>
        <div v-if="datasource.Email">
          <h4>Email address:</h4>
          <p>{{ datasource.Email }}</p>
        </div>
        <div v-if="datasource.Website">
          <h4>Website URL:</h4>
          <a :href="datasource.Website" target="_blank">{{ datasource.Website }}</a>
        </div>
        <div v-if="datasource.ContributorClassification">
          <h4>Classification:</h4>
          <p>{{ contributorClassification }}</p>
        </div>
        <button @click="edit" class="button has-text-white has-background-black" style="gap:10px">Edit</button>
      </div>
    </div>
  </section>
</template>

<script>
import DepositionsHeader from '@/components/cs-depositions/shared/header.vue';
import CopyButton from '@/components/shared/copy-button.vue';
import Loading from '@/components/shared/loading.vue';
import { ChemspiderDataService, ChemspiderApiService } from "@/api";
import iso3166 from 'iso-3166-1';

export default {
  name: 'ViewDatasource',
  components: {
    DepositionsHeader,
    CopyButton,
    Loading,
  },
  data() {
    return {
      loading: false,
      datasourceId: this.$route.path.split('/').pop(),
      legacyId: '',
      datasource: {
        Active: '',
        City: '',
        CompanyName: '',
        ContributorClassification: [],
        Country: '',
        DateCreated: '',
        DateUpdated: '',
        Description: '',
        Email: '',
        Fax: '',
        Hidden: false,
        Name: '',
        Phone: '',
        State: '',
        StreetAddress: '',
        Trusted: false,
        Website: '',
        ZipCode: ''
      },
      logo: '',

    };
  },
  computed: {
    countryName() {
    const country = iso3166.whereAlpha2(this.datasource.Country)?.country || this.datasource.Country;
    return country === "United Kingdom of Great Britain and Northern Ireland" ? "United Kingdom" : country;
  },
    decodedDescription() {
      const tempElement = document.createElement('div');
      tempElement.innerHTML = this.datasource.Description;
      return tempElement.innerText || tempElement.textContent;
    },
    contributorClassification() {
      let options = '';
      this.datasource.ContributorClassification.forEach((o, i) => {
        if (o.ShortName) options = `${options}${i > 0 ? ', ' : ''}${o.ShortName}`;
      });

      return options;
    }
  },
  methods: {
    async initDatasource() {
      const response = await ChemspiderDataService.getDatasource(this.datasourceId);
      if (!response.ServiceStatus.HasError) {
        this.legacyId = response.Data.legacyId;
      }
      const [data, logo] = await Promise.all([
        ChemspiderApiService.getDatasource(this.legacyId),
        ChemspiderApiService.getDatasourceLogo(this.legacyId)
      ]);
      this.datasource = { ...data.Data };
      this.logo = logo.Data;
    },
    edit() {
      this.$router.push({ name: 'CSDatasourcesEdit' });
    }
  },
  async mounted() {
    this.loading = true;
    await this.initDatasource();
    this.loading = false;

  }
};


</script>
<style scoped>
.datasource-id-wrapper {
  display: flex;
  flex-flow: row nowrap;
  gap: 8px;
}

.datasource-main-info {
  background-color: #F2F2F2;
  width: 100%;
  padding: 15px;
  display: flex;
  flex-flow: column nowrap;
  gap: 8px;
}

.datasource-contact-info {
  margin-top: 20px;
  display: flex;
  flex-flow: column nowrap;
  align-items: start;
  gap: 12px;
  max-width:583px
}

h3 {
  font-weight: bold;
  font-size: large;
}

h4 {
  font-weight: bold;
}
</style>


