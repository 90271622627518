import {
  RESET_ARTICLE_EDITOR,
  IMPORT_ARTICLE_TO_EDITOR,
  ADD_MULTI_ROW_VALUE,
  UPDATE_MULTI_ROW_VALUE,
  REMOVE_MULTI_ROW_VALUE,
  REMOVE_VALUE_FROM_ARRAY,
} from '@/store/mutation-types';
import { getField, updateField } from 'vuex-map-fields';
import { merge } from 'lodash';
import { WorkflowStatus } from '@/workflow-status';

export default {

  namespaced: true,

  // STATE
  state: {
    article: {
      articleId: '',
      authors: [{ firstName: '', lastName: '' }],
      categories: [],
      doi: '',
      firstPage: '',
      issueNumber: '',
      journalTitle: '',
      journalVolume: '',
      lastPage: '',
      note: '',
      relatedArticles: [],
      status: WorkflowStatus.DRAFT,
      substances: [],
      subsYear: '',
      taxonomies: [],
      title: '',
      trivialNames: [],
    },
  },

  // MUTATIONS
  mutations: {
    updateField,
    [RESET_ARTICLE_EDITOR](state) {
      state.article = {
        articleId: '',
        authors: [{ firstName: '', lastName: '' }],
        categories: [],
        doi: '',
        firstPage: '',
        issueNumber: '',
        journalTitle: '',
        journalVolume: '',
        lastPage: '',
        note: '',
        relatedArticles: [],
        status: WorkflowStatus.DRAFT,
        substances: [],
        subsYear: '',
        taxonomies: [],
        title: '',
        trivialNames: [],
      };
    },
    [IMPORT_ARTICLE_TO_EDITOR](state, payload) {
      merge(state.article, payload.article);
    },
    [ADD_MULTI_ROW_VALUE](state, payload) {
      state.article[payload.key].push(payload.value);
    },
    [UPDATE_MULTI_ROW_VALUE](state, payload) {
      state.article[payload.key].splice(payload.index, 1, payload.value);
    },
    [REMOVE_MULTI_ROW_VALUE](state, payload) {
      state.article[payload.key].splice(payload.index, 1);
    },
    [REMOVE_VALUE_FROM_ARRAY](state, payload) {
      const index = state.article[payload.key].indexOf(payload.value);
      if (index !== -1) {
        state.article[payload.key].splice(index, 1);
      }
    },
  },

  // ACTIONS
  actions: {
    resetArticleEditorAction({ commit }) {
      commit(RESET_ARTICLE_EDITOR);
    },
    importArticleToEditorAction({ commit }, article) {
      commit(RESET_ARTICLE_EDITOR);
      commit(IMPORT_ARTICLE_TO_EDITOR, { article });
    },
    addAuthorRowAction({ commit }) {
      commit(ADD_MULTI_ROW_VALUE, { key: 'authors', value: { firstName: '', lastName: '' } });
    },
    addTaxonomyRowAction({ commit }, value) {
      commit(ADD_MULTI_ROW_VALUE, { key: 'taxonomies', value });
    },
    addMultiRowValueAction({ commit }, { key, value }) {
      commit(ADD_MULTI_ROW_VALUE, { key, value });
    },
    updateMultiRowValueAction({ commit }, { key, index, value }) {
      commit(UPDATE_MULTI_ROW_VALUE, { key, index, value });
    },
    deleteMultiRowValueAction({ commit }, { key, index }) {
      commit(REMOVE_MULTI_ROW_VALUE, { key, index });
    },
    removeValueFromArrayAction({ commit }, { key, value }) {
      commit(REMOVE_VALUE_FROM_ARRAY, { key, value });
    },
  },

  // GETTERS
  getters: {
    getField,
  },

}
