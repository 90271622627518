<template>
  <fieldset>
    <button :id="`preview-${recordId}`" type="button" class="button is-small is-link is-outlined is-pulled-left" @click="$emit('preview')">
      <span class="icon"><i class="fas fa-external-link-alt"></i></span>
      <span>Preview</span>
    </button>
    <div class="buttons are-small is-right">
      <span v-for="(button, key) in buttons" :key="`${key}Button`">
        <button :id="`${key}-${recordId}`" v-if="button.visibleFor.includes(recordStatus)"
          type="button"
          class="button"
          :class="[ button.class, key === clickedButton ? 'is-loading' : '' ]"
          @click="handleClick(button.operation, key)">
          <span class="icon"><i class="fas" :class="button.icon"></i></span>
          <span>{{ button.label }}</span>
        </button>
      </span>
      <router-link :id="isReadOnly ? `read-only-${recordId}` : `edit-${recordId}`" :to="{ name: `${this.$route.name}Edit`, params: { id: recordId }}" class="button is-link">
        <span class="icon"><i :class="[ 'fas', isReadOnly ? 'fa-file' : 'fa-pen' ]"></i></span>
        <span>{{ isReadOnly ? 'View (read-only)' : 'Edit'}}</span>
      </router-link>
    </div>
  </fieldset>
</template>

<script>
import { WorkflowStatus } from '@/workflow-status';
export default {
  name: 'WorkflowButtons',
  data() {
    return {
      buttons: {
        'approval': {
          label: 'Send for approval',
          icon: 'fa-check',
          class: 'is-success',
          operation: 'dbSendToReview',
          visibleFor: [ WorkflowStatus.DRAFT ],
        },
        'approve': {
          label: 'Approve',
          icon: 'fa-check',
          class: 'is-success',
          operation: 'dbSendToApproved',
          visibleFor: [ WorkflowStatus.REVIEW ],
        },
        'publish': {
          label: 'Publish',
          icon: 'fa-check',
          class: 'is-success',
          operation: 'dbSendToPublished',
          visibleFor: [ WorkflowStatus.APPROVED ],
        },
        'reject': {
          label: 'Reject',
          icon: 'fa-times',
          class: 'is-danger',
          operation: 'dbSendToRejected',
          visibleFor: [ WorkflowStatus.REVIEW, WorkflowStatus.APPROVED ],
        },
        'draft': {
          label: 'Return to draft',
          icon: 'fa-undo',
          class: 'is-warning',
          operation: 'dbSendToDraft',
          visibleFor: [ WorkflowStatus.REJECTED, WorkflowStatus.PUBLISHED ],
        },
      },
    }
  },
  props: {
    recordId: {
      type: String,
      required: true,
    },
    recordStatus: {
      type: String,
      required: true,
    },
    clickedButton: {
      type: String,
      required: false,
    },
    isReadOnly: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleClick(operation, key) {
      this.$emit('update', operation, key);
    },
  },
}
</script>

<style lang="scss" scoped>
.buttons.is-right a.button:last-child {
  margin-right: 0;
}
</style>
